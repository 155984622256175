<div class="flex items-start gap-1 relative">
  <ng-container *ngFor="let item of pages">
    <ng-container [ngSwitch]="item.type">
      <button
        class="mr-1"
        (click)="prePage()"
        [disabled]="item.disabled"
        *ngSwitchCase="'prev'"
        [ngClass]="{ 'text-black border-black opacity-20 cursor-not-allowed': item.disabled }"
      >
        <div class="border border-blue-default w-10 h-10 flex justify-center items-center rounded-lg">
          <i nz-icon class="text-blue-default font-bold" nzType="left"></i>
        </div>
      </button>
      <ng-container *ngIf="pages && pages.length > 8">
        <button class="mr-1" (click)="firstPage()" *ngSwitchCase="'first'">
          <div class="border border-blue-default w-10 h-10 flex justify-center items-center rounded-lg">1</div>
        </button>
      </ng-container>
      <button
        class="font-12 w-10 h-10 flex justify-center items-center rounded-lg"
        [ngClass]="{
          'bg-blue-default border border-blue-default text-white': item.index === index,
          'hover:text-white border-none hover:bg-blue-default active:bg-blue-default': item.index !== index
        }"
        (click)="onIndexChange(item.index)"
        *ngSwitchCase="'page'"
      >
        {{ item.index }}
      </button>
      <button
        class="mr-1"
        (click)="nextPage()"
        [disabled]="item.disabled"
        *ngSwitchCase="'next'"
        [ngClass]="{ 'text-black border-black opacity-20 cursor-not-allowed': item.disabled }"
      >
        <div class="border border-blue-default w-10 h-10 flex justify-center items-center rounded-lg">
          <i nz-icon class="text-blue-default" nzType="right"></i>
        </div>
      </button>
    </ng-container>
  </ng-container>
</div>