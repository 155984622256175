export enum DeliveryType {
  atStore = 0,
  qrCode = 1,
  atHome = 2,
}

export const getDeliveryTypeStr = (type: DeliveryType) => {
  switch (type) {
    case DeliveryType.atStore:
      return 'Nhận SIM & Hòa mạng tại điểm giao dịch Mobifone';
    case DeliveryType.qrCode:
      return 'Nhận QR Code';
    case DeliveryType.atHome:
      return 'Nhận SIM & Hòa mạng tại nhà';
  }
};
