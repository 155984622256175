export const API_URL = {
  LOGIN: 'users/login',
  SEARCH_FILTER: 'catalog/searchFilter',
  GET_LIST_MSISDN: 'stock/filterMsisdn',
  BOOKING_DETAIL: 'stock/booking/detail',
  ORDER_LIST: 'order/list',
  PLACE: 'catalog/place',
  MOBISHOP_LOCATION: 'catalog/mobishop',
  RANDOM_MSISDN: 'stock/simData',
  PACKAGES: 'package/packages',
  MSISDN_DETAIL: 'stock/msisdnDetail',
  CHECK_KEEP_MSISDN: 'stock/booking/checkIsKeepNumber',
  LOCK_MSISDN: 'stock/lockMsisdn',
  UNLOCK_MSISDN: 'stock/unlockMsisdn',
  CREATE_ORDER: 'order/create',
  STORE_USER_INFO: 'order/checkUser',
  CANCEL_ORDER: 'order/cancel',
  CREATE_MOMO_TRANS: 'payment/momo/createTrans',
  CREATE_VNPAY_TRANS: 'payment/vnpay/getUrlPay',
  GET_CAPTCHA: 'captcha/get'
};
