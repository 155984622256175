export enum OrderStatus {
  waitingForConfirmation = 0,
  waitingForDelivery = 1,
  readyForDelivery = 2,
  inDelivery = 3,
  delivered = 4,
  canceledByNotContactCustomer = 5,
  canceledByCustomerRequest = 6,
  canceledByOtherReason = 7,
  canceledByGHTK = 8,
  cancelBySeller = 9,
}

export const getOrderStatusStr = (status: OrderStatus) => {
  switch (status) {
    case OrderStatus.waitingForConfirmation:
      return 'Chờ xác nhận';
    case OrderStatus.waitingForDelivery:
      return 'Chờ lấy hàng';
    case (OrderStatus.readyForDelivery, OrderStatus.inDelivery):
      return 'Đang giao hàng';
    case OrderStatus.delivered:
      return 'Đã giao hàng';
    case OrderStatus.cancelBySeller:
    case OrderStatus.canceledByCustomerRequest:
    case OrderStatus.canceledByGHTK:
    case OrderStatus.canceledByNotContactCustomer:
    case OrderStatus.canceledByOtherReason:
      return 'Đã huỷ';
    default:
      return '';
  }
};

export const getOrderStatusColor = (type: OrderStatus) => {
  switch (type) {
    case OrderStatus.waitingForConfirmation:
      return '#9E77ED';
    case OrderStatus.waitingForDelivery:
      return '#FF991F';
    case (OrderStatus.readyForDelivery, OrderStatus.inDelivery):
      return '#1570EF';
    case OrderStatus.delivered:
      return '#12B76A';
    default:
      return '#F04438';
  }
};
