import { ROUTER } from "./router.constant";

export const MAIN_MENU = [
  {
    id: 'sim-normal',
    label: 'SIM thường',
    path: ROUTER.SIM_NORMAL
  },
  {
    id: 'sim-data',
    label: 'SIM Data',
    path: ROUTER.SIM_DATA
  },
  {
    id: 'sim-commit',
    label: 'SIM cam kết',
    path: ROUTER.SIM_COMMIT
  }
];

export const SUB_MENU = [
  {
    id: 'shop-location',
    label: 'Điểm giao dịch',
    icon: './assets/icons/shop.svg',
    path: ROUTER.SHOP_LOCATION
  },
  {
    id: 'order-lookup',
    label: 'Tra cứu giữ số',
    icon: './assets/icons/search.svg',
    path: ROUTER.ORDER_LOOKUP
  }
];