import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MAIN_MENU, SUB_MENU } from 'src/app/shared/constants/menu.constant';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent {
  @Output() close = new EventEmitter();

  readonly mainMenu = MAIN_MENU;
  readonly subMenu = SUB_MENU;

  constructor(
    private activatedRoute: ActivatedRoute
  ) {
  }

  get queryParams() {
    const rf = this.activatedRoute.snapshot.queryParams['rf'];
    return { rf };
  }

  onClose() {
    this.close.emit();
  }
}
