import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ]
})
export class InputComponent implements ControlValueAccessor {
  @ViewChild('input') inputRef: ElementRef;

  @Input() formControlName = '';
  @Input() type: 'text' | 'number' | 'tel' | 'file' | 'search' = 'text';
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() size: 'large' | 'small' | 'default' = 'default';
  @Input() max: number;
  @Input() min: number;
  @Input() maxLength: number;
  @Input() allowClear = false;
  @Input() isHasError = false;
  @Input() groupClass: string = '';
  @Output() clear = new EventEmitter<any>();
  @Output() enter = new EventEmitter<boolean>();

  @ViewChild('clearIcon') clearIcon: TemplateRef<any>;
  @ViewChild('warningIcon') warningIcon: TemplateRef<any>;

  value!: string;
  isFocused = false;

  _onChange!: (_: any) => void;
  _onTouched!: (_: any) => void;

  constructor(
    private cdr: ChangeDetectorRef
  ) {}

  onChange(event: any): void {
    if (event === '') {
      event = null;
      this.value = event;
    }
    if (this._onChange) {
      this._onChange(event);
    }
  }
  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onEnter(val: any) {
    this.enter.emit(val);
  }

  onFocus() {
    this.isFocused = true;
  }

  onBlur() {
    setTimeout(() => {
      this.isFocused = false;
      this.cdr.detectChanges();
    }, 500)
  }

  onClear() {
    this.clear.emit();
    this.value = '';
    this.onChange('');
    this.cdr.detectChanges();
  }

  focusAndScroll() {
    if (this.inputRef && this.inputRef.nativeElement) {
      this.inputRef.nativeElement.focus();
    }
  }
}
