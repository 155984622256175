import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { BookingDetail } from '../../models/booking-detail';
import { LocalCurrencyPipe } from '../../pipes/currency.pipe';
import { PhoneNumberPipe } from '../../pipes/phone-number.pipe';
import {
  DeliveryType,
  getDeliveryTypeStr,
} from '../../enums/order/delivery.enum';
import {
  SimType,
  getSubsStatusStr,
  getSubsTypeStr,
} from '../../enums/order/subs.enum';
import { NULL_STRING_PLACEHOLDER } from '../../constants/texts.constant';
import {
  OrderStatus,
  getOrderStatusColor,
  getOrderStatusStr,
} from '../../enums/order/order-status.enum';
import {
  PaymentChannel,
  getPaymentChannelStr,
} from '../../enums/order/payment.enum';
import { NzModalService } from 'ng-zorro-antd/modal';
import { PAYMENT_OPTIONS } from 'src/app/modules/msisdn-detail-and-order-booking/components/order-and-booking-sim/components/order-booking-form/radio-options.constants';
import { ROUTER } from '../../constants';
import { RouterNavigateService } from 'src/app/core/services/router-navigate.service';
import { CookieService } from 'ngx-cookie-service';

interface CustomOrderDetailField {
  label: string;
  value: string;
  isOrderStatus?: boolean;
  isBoldValue?: boolean;
  isBoldRow?: boolean;
  isCode?: boolean;
  hasDashedLine?: boolean;
  isMsisdn?: boolean;
  orderStatusColor?: string;
}

@Component({
  selector: 'app-order-info',
  templateUrl: './order-info.component.html',
})
export class OrderInfoComponent implements OnChanges {
  @ViewChild('switchPaymenModal') switchPaymenModal: TemplateRef<any>;

  @Input() orderInfo: BookingDetail;
  @Input() isOrderResult: boolean = false;
  @Input() hasPaid: boolean = false;
  @Input() expiredDate: string = NULL_STRING_PLACEHOLDER;
  @Input() isOrderCancelled = false;
  @Output() rePayment = new EventEmitter();
  @Output() onCancelOrder = new EventEmitter();
  @Output() onClickOrderPayment = new EventEmitter();

  orderInfoArray: CustomOrderDetailField[];
  deliveredAddressSection: CustomOrderDetailField;
  customerName: string;
  customerPhoneNumber: string;
  customerAddress?: string;
  customerEmailField: CustomOrderDetailField;
  paymentChannel: string = PaymentChannel.vnpay_qr;
  paymentChannelStr: string;
  orderCode: string;
  isDeliveryAtHome: boolean;
  isESIM: boolean;
  paymentChannelLogo: string;
  paymentInfo: CustomOrderDetailField[];
  invoice: CustomOrderDetailField[];
  onlinePaymentChannels = PAYMENT_OPTIONS;
  currentPaymentChannelIndex: number = 0;

  paymentOptions: any[] = PAYMENT_OPTIONS;
  readonly PaymentChannel = PaymentChannel;
  readonly OrderStatus = OrderStatus;
  readonly ROUTER = ROUTER;

  isOrderCodeCopied = false;
  isBookingCodeCopied = false;

  constructor(
    private router: RouterNavigateService,
    private localCurrency: LocalCurrencyPipe,
    private localPhoneNumber: PhoneNumberPipe,
    private modalService: NzModalService,
    private cookieService: CookieService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('orderInfo' in changes && this.orderInfo) {
      this.orderInfoArray = this.convertLookUpResToOrderDetail(this.orderInfo);
      this.filterOrderInfoData();
    }
  }

  filterOrderInfoData() {
    this.deliveredAddressSection = this.orderInfoArray[1];
    this.isDeliveryAtHome =
      this.orderInfo.orderInfo?.deliveryType === DeliveryType.atHome;
    this.isESIM = this.orderInfo.simType === SimType.eSim;
    this.paymentChannelLogo = this.choosePaymentChannelLogo(
      this.orderInfo.orderInfo?.paymentChannel
    );
    this.customerName = this.orderInfo.fullName;
    this.customerPhoneNumber = this.orderInfo.msisdnContact;
    this.customerAddress =
      `${this.orderInfo.address ? `${this.orderInfo.address}, ` : ''}${
        this.orderInfo.wardStr ? `${this.orderInfo.wardStr}, ` : ''
      }${this.orderInfo.districtStr ? `${this.orderInfo.districtStr}` : ''}${
        this.orderInfo.cityStr ? `, ${this.orderInfo.cityStr}` : ''
      }` || NULL_STRING_PLACEHOLDER;
    this.customerEmailField = this.orderInfoArray[2];
    //this.paymentChannel = this.orderInfo.orderInfo.paymentChannel;
    (this.paymentChannel = this.cookieService.get(this.orderInfo.bookingCode)),
      (this.paymentChannelStr = getPaymentChannelStr(
        this.orderInfo.orderInfo.paymentChannel
      ));
    this.orderCode = this.orderInfo.orderInfo.orderCode;
    this.paymentInfo = this.orderInfoArray.slice(7, 11);
    this.invoice = this.orderInfoArray.slice(11);
    if (this.isOrderResult && !this.hasPaid) {
      this.getIndexForOnlinePaymentChannelResponse();
    }
  }

  getIndexForOnlinePaymentChannelResponse() {
    const currentPaymentChannel = this.onlinePaymentChannels.find(
      (pc) => pc.value === this.paymentChannel
    );
    if (currentPaymentChannel != null) {
      this.currentPaymentChannelIndex = this.onlinePaymentChannels.indexOf(
        currentPaymentChannel
      );
    }
  }

  getCurrentOnlinePaymentChannel() {
    return this.onlinePaymentChannels[this.currentPaymentChannelIndex];
  }

  getOtherOnlinePaymentChannels() {
    const len = this.onlinePaymentChannels.length;
    let otherPaymentChannels = [];
    for (let i = 1; i < len; i++) {
      otherPaymentChannels.push(
        this.onlinePaymentChannels[(this.currentPaymentChannelIndex + i) % len]
      );
    }
    return otherPaymentChannels;
  }

  switchOnlinePaymentChannel() {
    this.modalService.create({
      nzTitle: '',
      nzContent: this.switchPaymenModal,
      nzFooter: null,
      nzClosable: false,
      nzBodyStyle: { padding: '0' },
      nzCentered: true,
    });
  }

  orderSwitchPayment() {
    const expired = new Date();
    expired.setHours(expired.getHours() + 24);
    this.cookieService.set(
      this.orderInfo.bookingCode,
      this.paymentChannel,
      expired,
      '/'
    );
    this.rePayment.emit({
      paymentChannel: this.paymentChannel,
      isBooking: false,
    });
  }

  closeModal() {
    this.modalService.closeAll();
  }

  choosePaymentChannelLogo(paymentChannel: string): string {
    switch (paymentChannel) {
      case 'VNPAY':
        return './assets/images/vnpay.webp';
      case 'COD':
        return './assets/images/cod.webp';
      case 'MOMO':
        return './assets/images/momo.webp';
      default:
        return './assets/images/mbf-money.webp';
    }
  }

  convertLookUpResToOrderDetail(orderDetail: any): CustomOrderDetailField[] {
    return [
      {
        label: 'Phương thức vận chuyển',
        value: getDeliveryTypeStr(
          orderDetail.orderInfo?.deliveryType ?? DeliveryType.atStore
        ),
      },
      {
        label: 'Địa chỉ nhận hàng',
        value: orderDetail.address ?? NULL_STRING_PLACEHOLDER,
      },
      {
        label: 'Email',
        value: orderDetail.email ?? NULL_STRING_PLACEHOLDER,
      },
      {
        label: 'Họ và tên',
        value: orderDetail.fullName,
      },
      {
        label: 'Số điện thoại',
        value: this.localPhoneNumber.transform(orderDetail.msisdnContact, ' '),
      },
      {
        label: 'Phương thức thanh toán',
        value: getPaymentChannelStr(orderDetail.orderInfo.paymentChannel),
      },
      {
        label: 'Mã đơn hàng',
        value: orderDetail.orderInfo?.orderCode,
        isCode: true,
      },
      {
        label: 'Thời gian đặt hàng',
        value: orderDetail.bookingDate,
      },
      {
        label: 'Thời gian thanh toán',
        value: this.hasPaid
          ? orderDetail.orderInfo.paymentDate
          : NULL_STRING_PLACEHOLDER,
      },

      {
        label: 'Trạng thái đơn hàng',
        value: getOrderStatusStr(orderDetail.orderInfo.status),
        isOrderStatus: true,
        orderStatusColor: getOrderStatusColor(orderDetail.orderInfo.status),
      },
      {
        label: 'Trạng thái thanh toán',
        value: orderDetail.orderInfo.paymentStatusStr,
      },
      {
        label: 'Mã giữ số',
        value: orderDetail.bookingCode,
        isCode: true,
      },
      {
        label: 'Số thuê bao',
        value: this.localPhoneNumber.transform(orderDetail.msisdn, ' '),
        isBoldValue: true,
        isMsisdn: true,
      },
      {
        label: 'Loại thuê bao',
        value: getSubsTypeStr(orderDetail.subsType),
      },
      {
        label: 'Loại số',
        value: orderDetail.groupName,
      },
      {
        label: 'Khu vực hoà mạng',
        value: orderDetail.telcoRegionStr,
      },
      {
        label: 'Cước cam kết (VNĐ/tháng)',
        value: this.localCurrency.transform(orderDetail.committedFee, 'đ'),
      },
      {
        label: 'Phí giữ số',
        value: '0đ',
      },
      {
        label: 'Phí hoà mạng',
        value: this.localCurrency.transform(orderDetail.msisdnFee, 'đ'),
      },
      {
        label: 'Gói cước đăng ký',
        value: orderDetail.packageBase || NULL_STRING_PLACEHOLDER,
      },
      {
        label: 'Giá gói cước/dịch vụ',
        value: orderDetail.packagePrice
          ? this.localCurrency.transform(orderDetail.packagePrice, 'đ')
          : NULL_STRING_PLACEHOLDER,
      },
      {
        label: 'Trạng thái thuê bao',
        value: getSubsStatusStr(orderDetail.statusMsisdn),
      },
      {
        label: 'Ghi chú',
        value: orderDetail.orderInfo.note ?? NULL_STRING_PLACEHOLDER,
        hasDashedLine: true,
      },
      {
        label: 'Tổng tiền hàng',
        value: this.localCurrency.transform(
          orderDetail.orderInfo?.amount - orderDetail.orderInfo?.shipFee,
          'đ'
        ),
      },
      {
        label: 'Phí giao hàng',
        value: this.localCurrency.transform(orderDetail.orderInfo.shipFee, 'đ'),
      },
      {
        label: 'Tổng thanh toán',
        value: this.localCurrency.transform(orderDetail.orderInfo?.amount, 'đ'),
        isBoldRow: true,
      },
    ];
  }

  navigateHomePage() {
    if (this.router.url.includes(ROUTER.WAP_MOBIFONE)) {
      this.router.navigate([ROUTER.WAP_MOBIFONE]);
    } else {
      this.router.navigate([ROUTER.HOMEPAGE]);
    }
  }

  orderPayment(orderInfo: any) {
    this.onClickOrderPayment.emit({ info: orderInfo, isBooking: false });
  }

  cancelOrder() {
    this.onCancelOrder.emit();
  }

  get isAllowCancelOrder() {
    return (
      this.orderInfo.orderInfo.status === OrderStatus.waitingForConfirmation ||
      this.orderInfo.orderInfo.status === OrderStatus.waitingForDelivery
    );
  }

  copyToClipboard(content: string, type: 'orderCode' | 'bookingCode') {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(content)
        .then(() => {
          console.log('Đã sao chép: ' + content);
          this.isOrderCodeCopied = type === 'orderCode';
          this.isBookingCodeCopied = type === 'bookingCode';
          setTimeout(() => {
            this.isOrderCodeCopied = false;
            this.isBookingCodeCopied = false;
          }, 3000);
        })
        .catch((err) => {
          console.error('Có lỗi xảy ra khi sao chép: ', err);
        });
    } else {
      alert('Trình duyệt của bạn không hỗ trợ chức năng sao chép này.');
    }
  }
}
