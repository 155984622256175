<nz-layout class="bg-inherit">
  <div class="rounded-2xl border shadow">
    <div class="bg-sim-card bg-cover bg-center bg-no-repeat rounded-t-2xl rounded-b-xl">
      <div class="border-b border-blue-700 px-6 py-4 lg:p-6">
        <div class="flex justify-between items-center mb-6">
          <p class="text-white text-[15px] lg:text-xl font-semibold">
            {{msisdnInfo?.type === msisdnType.PRE_PAY ? 'Thuê bao trả trước' : 'Thuê bao trả sau'}}
          </p>
          <div class="w-[100px] lg:w-[132px]">
            <img src="./assets/images/logo-mobifone-card.webp" alt="">
          </div>
        </div>
        <div class="flex justify-between items-center">
          <p class="text-white text-[26px] lg:text-[33px] font-semibold">{{msisdnInfo?.msisdn | phoneNumber: ' '}}</p>
          <div class="w-[58px] lg:w-[75px]">
            <img src="./assets/images/sim-card.webp" alt="">
          </div>
        </div>
      </div>
      <div class="px-6 py-4 lg:p-6">
        <p class="text-white text-base lg:text-xl font-semibold">Gói cước đăng ký</p>
        <p class="text-white text-xl lg:text-2xl font-semibold">{{packageSelected?.packageName || '-'}}</p>
      </div>
    </div>
    <div class="p-4">
      <div class="grid grid-cols-2 xl:grid-cols-3 gap-2">
        @for (item of msisdnSubInfo; track $index) {
          <div class="col-span-1">
            <p class="text-gray-400">{{item.label}}</p>
            <p class="text-black text-lg font-medium">{{item.content}}</p>
          </div>
        }
      </div>
    </div>
  </div>
</nz-layout>