import { NgModule } from '@angular/core';
import { NumberOnlyDirective } from './number-only.directive';
import { PhoneNumberDirective } from './phone-number.directive';

const directives = [
  NumberOnlyDirective,
  PhoneNumberDirective
];

@NgModule({
  declarations: directives,
  exports: directives
})
export class DirectivesModule {}
