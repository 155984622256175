import { Pipe, PipeTransform } from '@angular/core';
import { NumberUtil } from 'src/app/core/utils';

@Pipe({
  name: 'localCurrency'
})
export class LocalCurrencyPipe implements PipeTransform {
  transform(value: any, unit: string): any {
    return `${NumberUtil.toCurrency(value)}${unit}`;
  }
}
