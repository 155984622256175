import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-slide-carousel',
  templateUrl: './slide-carousel.component.html',
  styleUrl: './slide-carousel.component.scss',
  encapsulation: ViewEncapsulation.Emulated
})
export class SlideCarouselComponent {

  @Input() slides: any[];
  @Input() slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    autoplay: false,
    arrows: true,
    infinite: false,
  };
  @Input() allowSelect = false;
  @Input() isShowButton = true;
  @Output() onItemSelect = new EventEmitter();

  onClick(data: any) {
    this.slides.forEach(item => {
      item.selected = item.packageName === data.packageName;
    });
    this.onItemSelect.emit(data);
  }
}
