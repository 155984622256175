import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  private readonly domain = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;

  readonly downloadIcons = [
    {
      src: './assets/icons/apple.svg',
      link: 'https://apps.apple.com/vn/app/my-mobifone/id719320091'
    },
    {
      src: './assets/icons/android.svg',
      link: 'https://play.google.com/store/apps/details?id=vms.com.vn.mymobifone&pli=1'
    },
    {
      src: './assets/icons/huawei.svg',
      link: 'https://appgallery.huawei.com/app/C102512327?sharePrepath=ag&channelId=web&detailType=0'
    }
  ];

  readonly connectIcons = [
    {
      src: './assets/icons/facebook.svg',
      link: 'https://www.facebook.com/mobifone'
    },
    {
      src: './assets/icons/youtube.svg',
      link: 'https://www.youtube.com/@mobifone.official'
    },
    {
      src: './assets/icons/news.svg',
      link: 'http://bantinketnoi.com.vn/2024/thang03/pdf/ban-tin-ket-noi-mobifone.pdf?t=739'
    },
    {
      src: './assets/icons/tiktok.svg',
      link: 'https://www.tiktok.com/@mobifone.official'
    },
    {
      src: './assets/icons/zalo.svg',
      link: 'https://zalo.me/4055244641440308778'
    }
  ];

  readonly introduceMbf = [
    {
      label: 'Giới thiệu MobiFone',
      link: 'https://www.mobifone.vn/gioi-thieu'
    },
    {
      label: 'Hợp tác MobiFone',
      link: 'https://www.mobifone.vn/gioi-thieu/hop-tac-voi-mobifone'
    },
    {
      label: 'Tuyển dụng',
      link: 'https://www.mobifone.vn/tuyen-dung'
    },
    {
      label: 'Tải Logo',
      link: `${this.domain}/assets/source/logofontchuMobiFone.rar`
    }
  ];

  readonly supportGuest = [
    {
      label: 'Gửi phản ánh',
      link: 'https://www.mobifone.vn/tai-khoan/dang-nhap-nhanh?referal=https%3A%2F%2Fwww.mobifone.vn%2Fho-tro-khach-hang%2Fphan-anh'
    },
    {
      label: 'Câu hỏi thường gặp',
      link: 'https://www.mobifone.vn/ho-tro-khach-hang/cau-hoi-thuong-gap'
    },
    {
      label: 'Tìm kiếm cửa hàng',
      link: 'https://www.mobifone.vn/ho-tro-khach-hang/vi-tri-cua-hang'
    },
    {
      label: 'Chuyển mạng giữ số',
      link: 'https://chuyenmang.mobifone.vn/'
    },
    {
      label: 'Sitemap',
      link: 'https://www.mobifone.vn/site-map'
    },
    {
      label: 'Đăng ký thông tin',
      link: 'https://www.mobifone.vn/tai-khoan/kiem-tra-dang-ky-thong-tin'
    },
  ];

  readonly privateAndPolicy = [
    {
      label: 'Điều khoản sử dụng',
      link: 'https://www.mobifone.vn/dieu-khoan-su-dung'
    },
    {
      label: 'Bảo mật thông tin',
      link: 'https://www.mobifone.vn/bao-mat-thong-tin'
    }
  ];
}
