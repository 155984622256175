import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTER } from 'src/app/shared/constants';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss'
})
export class MainComponent {

  constructor(
    private router: Router
  ) {}

  get isShowStep() {
    return this.router.url.includes(ROUTER.MSISDN_DETAIL) || this.router.url.includes(ROUTER.ORDER_BOOKING_MSISDN) || this.router.url.includes(ROUTER.ORDER_RESULT);
  }
}
