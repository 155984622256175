<nz-input-group
  [class]="'border rounded-lg shadow-none ' + groupClass"
  [nzPrefix]="prefix"
  [nzSuffix]="suffixIcons"
  [nzSize]="size"
>
  <input
    nz-input
    class="h-full placeholder:text-gray-500 placeholder:text-base"
    [(ngModel)]="value"
    (ngModelChange)="onChange($event)"
    [type]="type"
    [disabled]="disabled"
    [placeholder]="placeholder"
    [style.width]="width"
    [maxlength]="maxLength"
    [minlength]="minLength"
    (focus)="onFocus()"
    (blur)="onBlur()"
  />
</nz-input-group>

<ng-template #suffixIcons>
  <div class="flex items-center gap-2">
    <button type="button" *ngIf="value && isFocused" (click)="onClear()">
      <span
      nz-icon
      nzType="close-circle"
      nzTheme="outline"
      class="text-gray-400 text-base"
      ></span>
    </button>
    <div>
      <ng-container *ngTemplateOutlet="suffix"></ng-container>
    </div>
  </div>
</ng-template>