import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-destroy',
  templateUrl: './destroy.component.html',
  styleUrl: './destroy.component.scss'
})
export class DestroyComponent implements OnDestroy{
  protected readonly destroy$ = new Subject<void>();

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
