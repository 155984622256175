import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ZorroAntdModule } from '../zorro-antd.module';
import { FormControlComponent } from './form-control/form-control.component';
import { ControlsModule } from '../controls/controls.module';
import { PipesModule } from '../pipes/pipes.module';
import { DirectivesModule } from '../directives/directives.module';
import { NotificationComponent } from './notification/notification.component';
import { TableComponent } from './table/table.component';
import { LoadingComponent } from './loading/loading.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PaginationComponent } from './pagination/pagination.component';
import { DestroyComponent } from './destroy/destroy.component';
import { CardPackageComponent } from './card-package/card-package.component';
import { SlideCarouselComponent } from './slide-carousel/slide-carousel.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ModalNoResultComponent } from './modal-no-result/modal-no-result.component';
import { SimCardInfoComponent } from './sim-card-info/sim-card-info.component';
import { StepComponent } from './step/step.component';
import { OrderInfoComponent } from './order-info/order-info.component';
import { BookingInfoComponent } from './booking-info/booking-info.component';

const components = [
  FormControlComponent, 
  NotificationComponent,
  TableComponent,
  LoadingComponent,
  PaginationComponent,
  DestroyComponent,
  CardPackageComponent,
  SlideCarouselComponent,
  ModalNoResultComponent,
  SimCardInfoComponent,
  StepComponent,
  OrderInfoComponent,
  BookingInfoComponent
];
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ZorroAntdModule,
    ReactiveFormsModule,
    ControlsModule,
    PipesModule,
    NgxSpinnerModule,
    DirectivesModule,
    SlickCarouselModule,
  ],
  exports: components,
  declarations: components,
})
export class ComponentsModule {}
