import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ITableColumns } from '../../models/table-columns.interface';
import { Area } from '../../enums/areas.enum';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss',
})
export class TableComponent {
  @Input() data: any[];
  @Input() columns: ITableColumns[];
  @Input() showPaging: boolean = false;
  @Output() onItemSelect = new EventEmitter();

  readonly area = Area;

  constructor(
    private deviceService: DeviceDetectorService
  ){}

  get isMobile() {
    return this.deviceService.isMobile();
  }

  showDetailArea(dwTypeName: string): string[] {
    return dwTypeName.split(', ');
  }

  onClick(msisdn: string) {
    this.onItemSelect.emit(msisdn);
  }
}