<div class="flex justify-between items-center">
  <ul nz-menu nzMode="horizontal" class="bg-inherit border-none">
    @for (item of mainMenu; track item.id) {
      <li 
        nz-menu-item 
        [routerLink]="item.path"
        [queryParams]="queryParams"
        [routerLinkActive]="'main-menu-active'"
        [nzMatchRouter]="true"
        class="text-gray-500 text-base align-middle top-0 after:border-none group main-menu"
      >
        {{ item.label }}
      </li>
    }
  </ul>
  <ul nz-menu nzMode="horizontal" class="bg-inherit border-none">
    @for (item of subMenu; track item.id) {
      <li 
        nz-menu-item 
        [routerLink]="item.path"
        [queryParams]="queryParams"
        [routerLinkActive]="'sub-menu-active'"
        [nzMatchRouter]="true"
        class="text-dark-cerulean text-base align-middle top-0 last:pr-0"
      >
        <div class="flex items-center gap-2">
          <img [src]="item.icon" alt="" />
          <span>{{item.label}}</span>
        </div>
      </li>
    }
  </ul>
</div>