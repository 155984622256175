@if(isBookingResult){
<div
  class="flex flex-col items-center mb-6 p-4 rounded-2xl text-center"
  [ngClass]="{
    'bg-[#FEF0C7]': !hasPaid && paymentChannel !== PaymentChannel.cod,
    'bg-blue-100': hasPaid || paymentChannel === PaymentChannel.cod
  }"
>
  <img
    [src]="
      hasPaid || paymentChannel === PaymentChannel.cod
        ? './assets/icons/check-circle.svg'
        : './assets/icons/x-circle.svg'
    "
    alt="Order status icon"
  />
  <p class="text-xl font-semibold">
    {{
      hasPaid || paymentChannel === PaymentChannel.cod
        ? "Đặt hàng thành công!"
        : "Đơn hàng chưa được thanh toán"
    }}
  </p>
  @if (bookingInfo) {
  <p class="text-base font-medium text-gray-700">
    {{
      hasPaid || paymentChannel === PaymentChannel.cod
        ? "Cảm ơn quý khách đã lựa chọn dịch vụ SIM số của MobiFone!"
        : "Quý khách vui lòng thanh toán trước " + bookingInfo.expDate
    }}
  </p>
  }
</div>
}

<div class="flex flex-col">
  <div class="flex flex-col lg:flex-row lg:space-x-10 justify-between">
    <div class="flex flex-col flex-1 space-y-4">
      <div class="flex flex-col p-4 space-y-2 shadow-sm rounded-lg bg-white">
        <div class="flex flex-row justify-between">
          <p class="text-base">Mã giữ số</p>
          <div class="flex flex-row space-x-2">
            @if (bookingCodeSection) {
            <p class="text-lg text-blue-600 font-bold">
              {{ bookingCodeSection.value }}
            </p>
            }
            <button
              class="bg-[#0000001A] px-3 py-1 text-[#27272A] text-[12px] rounded"
              (click)="copyToClipboard(bookingCodeSection.value)"
              nz-tooltip
              [nzTooltipTitle]="'Đã sao chép!'"
              [nzTooltipPlacement]="'bottom'"
              [nzTooltipTrigger]="'click'"
              [nzTooltipVisible]="isBookingCodeCopied"
            >
              Copy
            </button>
          </div>
        </div>
        <p class="text-[#C1C7D0] text-sm">
          Quý khách vui lòng lưu lại mã giữ số để tra cứu và thực hiện hòa mạng
        </p>
      </div>
      <div class="flex flex-col space-y-4">
        <p class="text-base font-bold">Thông tin khách hàng</p>
        <ul class="p-4 space-y-2 shadow-sm rounded-lg bg-white">
          <li
            *ngFor="let info of subsRegistrationInfoSection"
            class="flex flex-row justify-between text-base"
          >
            <p class="text-left">{{ info.label }}</p>
            <p class="text-right ml-4 w-1/2 break-words">{{ info.value }}</p>
          </li>
        </ul>
      </div>
      <div class="flex flex-col space-y-4">
        <p class="text-base font-bold">Phương thức thanh toán</p>
        <div
          class="flex flex-row rounded-lg bg-white p-4 shadow-sm justify-between"
        >
          @if(!hasPaid && paymentChannel !== PaymentChannel.partner &&
          bookingInfo.roamingStatus !== 4 && bookingInfo.status !== 4){
          <div class="flex flex-row flex-1 items-center mr-4">
            <img
              [src]="getCurrentOnlinePaymentChannel().prefix"
              alt="Payment channel icon"
            />
            <p class="ml-2 text-base">
              {{ getCurrentOnlinePaymentChannel().label }}
            </p>
          </div>
          <div class="flex flex-row flex-1 items-center justify-end">
            <p class="text-sx mr-1 text-gray-500">Thay đổi</p>
            <div class="flex flex-row">
              <div class="flex">
                <div
                  *ngFor="
                    let company of getOtherOnlinePaymentChannels();
                    let i = index
                  "
                  [ngStyle]="{ 'z-index': i + 1 }"
                  class="w-8 h-8 ml-5 absolute rounded-full border border-gray-300 overflow-hidden"
                  [ngClass]="{
                  'ml-5 absolute': i > 0,
                }"
                >
                  <img
                    [src]="company.prefix"
                    alt="{{ company.label }}"
                    class="object-cover object-center"
                  />
                </div>
              </div>
              <button (click)="switchOnlinePaymentChannel()">
                <img
                  src="./assets/icons/switch.svg"
                  alt="Switch icon"
                  class="ml-6"
                />
              </button>
            </div>
          </div>

          } @else {
          <div class="flex flex-row items-center">
            <img
              [src]="choosePaymentChannelLogo(paymentChannel)"
              alt="Payment channel icon"
            />
            <p class="ml-2 text-base">
              {{ paymentChannelStr }}
            </p>
          </div>
          }
        </div>
      </div>
      <div class="flex flex-col space-y-4">
        <p class="text-base font-bold">Thông tin đơn hàng</p>
        <ul class="p-4 space-y-2 shadow-sm rounded-lg bg-white">
          <li
            class="flex flex-row justify-between text-base"
            *ngFor="let info of orderInfoSection"
            [ngClass]="{ 'font-bold': info.isBoldRow }"
          >
            <p class="text-left font-bold:">{{ info.label }}</p>
            <p
              class="text-right ml-4 w-1/2"
              [ngStyle]="
                info.isBookingStatus ? { color: info.bookingStatusColor } : {}
              "
            >
              {{ info.value }}
            </p>
          </li>
        </ul>
      </div>
    </div>
    <div class="flex flex-col flex-1 space-y-4 mt-10 lg:mt-0">
      <p class="text-base font-bold">Thông tin thuê bao</p>
      <ul class="p-4 space-y-2 shadow-sm rounded-lg bg-white">
        <li
          class="flex flex-row justify-between text-base"
          *ngFor="let info of subsInfoSection"
        >
          <p class="text-left font-bold:">{{ info.label }}</p>
          <p
            class="text-right ml-4 w-1/2"
            [ngClass]="{ 'font-bold': info.isBoldValue }"
          >
            {{ info.value }}
          </p>
        </li>
      </ul>
    </div>
  </div>
  <p class="my-6 text-gray-500 text-center text-base italic">
    Để thực hiện hòa mạng, Quý khách mang CCCD/Hộ chiếu đã đăng ký tới Điểm giao
    dịch MobiFone gần nhất để được hỗ trợ
  </p>
  <div class="flex sm:self-center flex-col sm:flex-row gap-2">
    @if (!hasPaid && bookingInfo.roamingStatus !== 4 && bookingInfo.status !==
    4) {
    <button
      class="trading-point-btn w-full sm:w-[264px]"
      (click)="navigateHomePage()"
    >
      Trang chủ
    </button>
    <button
      class="back-btn w-full sm:w-[264px]"
      (click)="orderPayment(bookingInfo)"
    >
      Thanh toán
    </button>
    } @else {
    <button
      class="trading-point-btn w-full sm:w-[264px]"
      (click)="navigateMobishopList()"
    >
      Điểm giao dịch MobiFone
    </button>
    <button class="back-btn w-full sm:w-[264px]" (click)="navigateHomePage()">
      Chọn thêm SIM
    </button>
    } @if(bookingInfo.roamingStatus === RoamingStatus.notRoaming &&
    bookingInfo.status !== BookingStatus.expired) {
    <button
      class="text-base rounded bg-red-error-600 text-white px-2 py-3 w-full sm:w-[264px]"
      (click)="cancelOrder()"
    >
      Hủy đơn hàng
    </button>
    }
  </div>
</div>

<ng-template #switchPaymenModal>
  <div class="p-4 rounded-t-lg flex items-start justify-between gap-4">
    <div class="w-6"></div>
    <p class="text-black text-xl text-center font-bold">
      Phương thức thanh toán
    </p>
    <button (click)="closeModal()" class="outline-none">
      <span
        nz-icon
        nzType="close-circle"
        nzTheme="outline"
        class="text-2xl text-gray-300"
      ></span>
    </button>
  </div>
  <div class="p-4">
    <form>
      <app-radio-group
        [(ngModel)]="paymentChannel"
        name="paymentChannel"
        [options]="paymentOptions"
        display="vertical"
        groupClass="flex gap-2"
        labelClass="flex items-center border px-4 py-3 rounded-lg w-full m-0 bg-white"
      >
      </app-radio-group>
      <div class="flex justify-center mt-4">
        <button
          nz-button
          nzSize="large"
          type="button"
          class="text-base rounded border border-blue-600 bg-blue-600 text-white w-2/3 lg:w-1/2"
          (click)="orderSwitchPayment()"
        >
          Thanh toán
        </button>
      </div>
    </form>
  </div>
</ng-template>
