import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class StepService {

  private step$ = new BehaviorSubject<number>(0);

  setStep(data: number) {
    this.step$.next(data);
  }

  getStep() {
    return this.step$.asObservable();
  }
}