import { Component, Input, ViewEncapsulation, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface IRadioOptions {
  label: string,
  value: any,
  prefix?: string
}

@Component({
  selector: 'app-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => RadioGroupComponent),
			multi: true
		}
	]
})
export class RadioGroupComponent implements ControlValueAccessor {
  @Input() options: IRadioOptions[] = [];
  @Input() labelClass: string = '';
  @Input() groupClass: string = '';
  @Input() showScroll = false;
  @Input() display: 'vertical' | 'horizontal' = 'horizontal';

  value!: boolean;

  private _onChange!: (_: any) => void;
  private _onTouched!: (_: any) => void;

  writeValue(obj: any): void {
    this.value = obj
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  onChange(event: any) {
    if (this._onChange) {
  		this._onChange(event);
  	}
  }

}
