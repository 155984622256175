
// 0 - chưa hòa mạng, 2 - đang hòa mạng, 3 - hòa mạng thành công, 4 7 - hòa mạng thất bại
enum RoamingStatus {
  notRoaming = 0,
  roaming = 2,
  success = 3,
  roamingFailed = 4 // hoặc 7
}

export default RoamingStatus;
