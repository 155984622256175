import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { BookingDetail } from '../../models/booking-detail';
import { NULL_STRING_PLACEHOLDER } from '../../constants/texts.constant';
import {
  getSimTypeStr,
  getSubsStatusStr,
  getSubsTypeStr,
} from '../../enums/order/subs.enum';
import { LocalCurrencyPipe } from '../../pipes/currency.pipe';
import { PhoneNumberPipe } from '../../pipes/phone-number.pipe';
import {
  BookingStatus,
  getBookingStatusColor,
  getBookingStatusStr,
} from '../../enums/order/booking-status.enum';
import {
  DeliveryType,
  getDeliveryTypeStr,
} from '../../enums/order/delivery.enum';
import {
  PaymentChannel,
  getPaymentChannelStr,
} from '../../enums/order/payment.enum';
import { ROUTER } from '../../constants';
import { RouterNavigateService } from 'src/app/core/services/router-navigate.service';
import { PAYMENT_OPTIONS } from 'src/app/modules/msisdn-detail-and-order-booking/components/order-and-booking-sim/components/order-booking-form/radio-options.constants';
import { NzModalService } from 'ng-zorro-antd/modal';
import RoamingStatus from '../../enums/order/roaming-status.enum';
import { CookieService } from 'ngx-cookie-service';

interface CustomBookingDetailField {
  label: string;
  value: string;
  isBookingStatus?: boolean;
  isBoldRow?: boolean;
  isBoldValue?: boolean;
  bookingStatusColor?: string;
}

@Component({
  selector: 'app-booking-info',
  templateUrl: './booking-info.component.html',
  styleUrl: './booking-info.component.scss',
})
export class BookingInfoComponent implements OnChanges {
  @ViewChild('switchPaymenModal') switchPaymenModal: TemplateRef<any>;

  @Input() hasPaid: boolean = false;
  @Input() bookingInfo: BookingDetail;
  @Input() isBookingResult: boolean = false;
  @Input() isOrderCancelled = false;
  @Output() onCancelOrder = new EventEmitter();
  @Output() rePayment = new EventEmitter();
  @Output() onClickOrderPayment = new EventEmitter();

  bookingInfoArray: CustomBookingDetailField[];
  bookingCodeSection: CustomBookingDetailField;
  subsRegistrationInfoSection: CustomBookingDetailField[];
  orderInfoSection: CustomBookingDetailField[];
  subsInfoSection: CustomBookingDetailField[];

  isBookingCodeCopied = false;
  paymentChannel: string = PaymentChannel.vnpay_qr;
  paymentChannelStr: string = '';
  onlinePaymentChannels =PAYMENT_OPTIONS
  currentPaymentChannelIndex: number = 0;

  paymentOptions: any[] = PAYMENT_OPTIONS;

  readonly PaymentChannel = PaymentChannel;
  readonly DeliveryType = DeliveryType;
  readonly BookingStatus = BookingStatus;
  readonly RoamingStatus = RoamingStatus;

  constructor(
    private router: RouterNavigateService,
    private localCurrency: LocalCurrencyPipe,
    private localPhoneNumber: PhoneNumberPipe,
    private cookieService: CookieService,
    private modalService: NzModalService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('bookingInfo' in changes && this.bookingInfo) {
      this.bookingInfoArray = this.convertLookupResToBookingDetail(
        this.bookingInfo
      );
      this.filterBookingInfo();
    }
  }

  filterBookingInfo() {
    this.bookingCodeSection = this.bookingInfoArray[0];
    this.subsRegistrationInfoSection = this.bookingInfoArray.slice(1, 7);
    this.orderInfoSection = this.bookingInfoArray.slice(7, 14);
    this.subsInfoSection = this.bookingInfoArray.slice(14);

    // this.paymentChannel = this.bookingInfo.orderInfo.paymentChannel;
    this.paymentChannel = this.cookieService.get(this.bookingInfo.bookingCode);
    this.paymentChannelStr = getPaymentChannelStr(
      this.bookingInfo.orderInfo.paymentChannel
    );
    if (this.isBookingResult && !this.hasPaid) {
      this.getIndexForOnlinePaymentChannelResponse();
    }
  }

  convertLookupResToBookingDetail(
    bookingDetail: BookingDetail
  ): CustomBookingDetailField[] {
    return [
      {
        label: 'Mã giữ số',
        value: bookingDetail.bookingCode,
      },
      {
        label: 'Loại SIM',
        value: getSimTypeStr(bookingDetail.simType),
      },
      {
        label: 'Gói cước đăng ký',
        value: bookingDetail.packageBase || NULL_STRING_PLACEHOLDER,
      },
      { label: 'Họ và tên', value: bookingDetail.fullName },
      {
        label: 'Số điện thoại',
        value: this.localPhoneNumber.transform(
          bookingDetail.msisdnContact,
          ' '
        ),
      },
      { label: 'Email', value: bookingDetail.email ?? NULL_STRING_PLACEHOLDER },
      {
        label: 'Địa chỉ',
        value:
          `${bookingDetail.address ? `${bookingDetail.address}, ` : ''}${
            bookingDetail.wardStr ? `${bookingDetail.wardStr}, ` : ''
          }${bookingDetail.districtStr ? `${bookingDetail.districtStr}` : ''}${
            bookingDetail.cityStr ? `, ${bookingDetail.cityStr}` : ''
          }` || NULL_STRING_PLACEHOLDER,
      },
      {
        label: 'Trạng thái',
        value: bookingDetail.statusStr,
        isBookingStatus: true,
        bookingStatusColor: getBookingStatusColor(bookingDetail.status),
      },
      {
        label: 'Thời gian giữ số',
        value: bookingDetail.bookingDate,
      },
      {
        label: 'Thời gian hết hiệu lực giữ số',
        value: bookingDetail.expDate,
      },
      {
        label: 'Hình thức nhận SIM',
        value: getDeliveryTypeStr(bookingDetail.orderInfo.deliveryType),
      },
      {
        label: 'Tổng thanh toán',
        value: this.localCurrency.transform(
          bookingDetail.orderInfo.amount,
          'đ'
        ),
        isBoldRow: true,
      },
      {
        label: 'Phương thức thanh toán',
        value: getPaymentChannelStr(bookingDetail.orderInfo.paymentChannel),
      },
      {
        label: 'Trạng thái thanh toán',
        value: bookingDetail.orderInfo.paymentStatusStr,
      },
      {
        label: 'Số thuê bao',
        value: this.localPhoneNumber.transform(bookingDetail.msisdn, ' '),
        isBoldValue: true,
      },
      {
        label: 'Loại thuê bao',
        value: getSubsTypeStr(bookingDetail.subsType),
      },
      { label: 'Loại số', value: bookingDetail.groupName },
      {
        label: 'Khu vực hoà mạng',
        value: bookingDetail.telcoRegionStr,
      },
      {
        label: 'Cước cam kết (VNĐ/tháng)',
        value: this.localCurrency.transform(bookingDetail.committedFee, 'đ'),
      },
      {
        label: 'Phí giữ số',
        value: '0đ',
      },
      {
        label: 'Phí hoà mạng',
        value: this.localCurrency.transform(bookingDetail.msisdnFee, 'đ'),
      },
      {
        label: 'Gói cước đăng ký',
        value: bookingDetail.packageBase || NULL_STRING_PLACEHOLDER,
      },
      {
        label: 'Giá gói cước/dịch vụ',
        value: bookingDetail.packagePrice
          ? this.localCurrency.transform(bookingDetail.packagePrice, 'đ')
          : NULL_STRING_PLACEHOLDER,
      },
      {
        label: 'Trạng thái thuê bao',
        value:
          getSubsStatusStr(bookingDetail.statusMsisdn) ??
          NULL_STRING_PLACEHOLDER,
      },
      {
        label: 'Trạng thái hoà mạng',
        value: bookingDetail.roamingStatusStr,
      },
      {
        label: 'Ghi chú',
        value: bookingDetail.orderInfo?.note ?? NULL_STRING_PLACEHOLDER,
      },
    ];
  }

  getIndexForOnlinePaymentChannelResponse() {
    const currentPaymentChannel = this.onlinePaymentChannels.find(
      (pc) => pc.value === this.paymentChannel
    );
    if (currentPaymentChannel != null) {
      this.currentPaymentChannelIndex = this.onlinePaymentChannels.indexOf(
        currentPaymentChannel
      );
    }
  }

  getCurrentOnlinePaymentChannel() {
    return this.onlinePaymentChannels[this.currentPaymentChannelIndex];
  }

  getOtherOnlinePaymentChannels() {
    const len = this.onlinePaymentChannels.length;
    let otherPaymentChannels = [];
    for (let i = 1; i < len; i++) {
      otherPaymentChannels.push(
        this.onlinePaymentChannels[(this.currentPaymentChannelIndex + i) % len]
      );
    }
    return otherPaymentChannels;
  }

  choosePaymentChannelLogo(paymentChannel: string): string {
    switch (paymentChannel) {
      case PaymentChannel.vnpay:
        return './assets/images/vnpay.webp';
      case PaymentChannel.momo:
        return './assets/images/momo.webp';
      default:
        return './assets/images/mbf-money.webp';
    }
  }

  switchOnlinePaymentChannel() {
    this.modalService.create({
      nzTitle: '',
      nzContent: this.switchPaymenModal,
      nzFooter: null,
      nzClosable: false,
      nzBodyStyle: { padding: '0' },
      nzCentered: true,
    });
  }

  orderSwitchPayment() {
    const expired = new Date();
    expired.setHours(expired.getHours() + 24);
    this.cookieService.set(this.bookingInfo.bookingCode, this.paymentChannel, expired, '/');
    this.rePayment.emit({
      paymentChannel: this.paymentChannel,
      isBooking: true,
    });
  }

  orderPayment(bookingInfo: any) {
    this.onClickOrderPayment.emit({ info: bookingInfo, isBooking: true });
  }

  closeModal() {
    this.modalService.closeAll();
  }

  copyToClipboard(content: string) {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(content)
        .then(() => {
          console.log('Đã sao chép: ' + content);
          this.isBookingCodeCopied = true;
          setTimeout(() => {
            this.isBookingCodeCopied = false;
          }, 3000);
        })
        .catch((err) => {
          console.error('Có lỗi xảy ra khi sao chép: ', err);
        });
    } else {
      // Trường hợp trình duyệt không hỗ trợ API Clipboard
      alert('Trình duyệt của bạn không hỗ trợ chức năng sao chép này.');
    }
  }

  navigateHomePage() {
    if (this.router.url.includes(ROUTER.WAP_MOBIFONE)) {
      this.router.navigate([ROUTER.WAP_MOBIFONE]);
    } else {
      this.router.navigate([ROUTER.HOMEPAGE]);
    }
  }

  navigateMobishopList() {
    this.router.navigate([ROUTER.SHOP_LOCATION]);
  }

  cancelOrder() {
    this.onCancelOrder.emit();
  }
}
