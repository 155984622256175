export enum PaymentStatus {
  unpaid = 0,
  pending = 1,
  paid = 2,
  failed = 3,
}

export const getPaymentStatusStr = (status: PaymentStatus) => {
  switch (status) {
    case PaymentStatus.unpaid:
      return 'Chưa thanh toán';
    case PaymentStatus.pending:
      return 'Chờ thanh toán';
    case PaymentStatus.paid:
      return 'Thanh toán thành công';
    default:
      return 'Thanh toán thất bại';
  }
};

export enum PaymentChannel {
  cod = 'COD',
  momo = 'MOMO',
  vnpay = 'VNPAY',
  vnpay_qr = 'QR',
  vnpay_debit = 'DEBIT',
  vnpay_visa='VISA',
  mbfm = 'MBFM',
  partner = 'PARTNER',
}

export const getPaymentChannelStr = (pc: PaymentChannel) => {
  switch (pc) {
    case PaymentChannel.vnpay:
      return 'Thanh toán online qua cổng VNPay';
    case PaymentChannel.momo:
      return 'Ví MoMo';
    case PaymentChannel.cod:
      return 'Thanh toán khi nhận hàng';
    case PaymentChannel.mbfm:
      return 'Ví MobiFone Money';
    default:
      return 'Thanh toán tại Điểm giao dịch MobiFone';
  }
};
