<ngx-slick-carousel
  class="carousel"
  #slickModal="slick-carousel"
  [config]="slideConfig"
>
  @for (slide of slides; track $index) {
  <div ngxSlickItem class="slide">
    <div class="mx-2">
      <app-card-package
        [package]="slide"
        [isShowButton]="isShowButton"
        [allowSelect]="allowSelect"
        (onItemSelect)="onClick($event)"
      ></app-card-package>
    </div>
  </div>
  }
</ngx-slick-carousel>

<button #btn class="hidden"></button>