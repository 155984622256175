<nz-input-group
  [class]="'border rounded-lg shadow-none ' + groupClass"
  [ngClass]="isHasError ? 'border-[#FDA29B]' : ''"
  [nzSuffix]="isHasError && (!value || (value && !isFocused)) ? warningIcon : clearIcon"
>
  <input
    #input
    nz-input
    class="h-full placeholder:text-gray-500 placeholder:text-base"
    [type]="type"
    [placeholder]="placeholder"
    [(ngModel)]="value"
    [disabled]="disabled"
    (ngModelChange)="onChange($event)"
    [nzSize]="size"
    [maxlength]="maxLength"
    (keyup.enter)="onEnter($event)"
    (focus)="onFocus()"
    (blur)="onBlur()"
  />
</nz-input-group>

<ng-template #clearIcon>
  <button type="button" *ngIf="value && isFocused" (click)="onClear()">
    <span
      nz-icon
      nzType="close-circle"
      nzTheme="outline"
      class="text-gray-400 text-base"
    ></span>
  </button>
</ng-template>

<ng-template #warningIcon>
  <span
    nz-icon
    nzType="info-circle"
    nzTheme="outline"
    class="text-[#F04438] text-base"
  ></span>
</ng-template>