import { NgModule } from '@angular/core';
import { NumberToTextPipe } from './number-to-text.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { OptionsPipe } from './options.pipe';
import { LocalCurrencyPipe } from './currency.pipe';
import { PhoneNumberPipe } from './phone-number.pipe';

const pipes = [
  SafeHtmlPipe,
  LocalCurrencyPipe,
  NumberToTextPipe,
  OptionsPipe,
  PhoneNumberPipe
];

@NgModule({
  declarations: pipes,
  exports: pipes,
  providers: pipes
})
export class PipesModule {}
