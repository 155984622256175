import { ChangeDetectorRef, Component, DoCheck, OnInit } from '@angular/core';
import { Subscription, takeUntil } from 'rxjs';
import { DestroyService } from 'src/app/core/services';
import { RouterNavigateService } from 'src/app/core/services/router-navigate.service';
import { ROUTER } from 'src/app/shared/constants';
import { StepService } from 'src/app/shared/services/step.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {
  isSideMenuVisible = false;
  stepIndex: number = 0;

  stepSubscription: Subscription;

  constructor(
    private router: RouterNavigateService,
    private stepService: StepService,
    private cdr: ChangeDetectorRef,
    private readonly destroy$: DestroyService
  ) {}

  ngOnInit(): void {
    this.stepService.getStep().pipe(takeUntil(this.destroy$)).subscribe(index => {
      this.stepIndex = index;
      this.cdr.detectChanges();
    });
  }

  get isShowStep() {
    return this.router.url.includes(ROUTER.MSISDN_DETAIL) || this.router.url.includes(ROUTER.ORDER_BOOKING_MSISDN) || this.router.url.includes(ROUTER.ORDER_RESULT);
  }
}
