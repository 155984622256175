<nz-table
  #table
  [nzData]="data"
  [nzShowPagination]="showPaging"
  [nzNoResult]="noResult"
  class="rounded-2xl border shadow"
>
  <thead>
    <tr class="rounded-t-2xl">
      @for (column of columns; track column.key;) {
      <th
        [nzWidth]="column.isShowListLocation ? '240px' : ''"
        class="text-black  bg-gray-100 font-semibold"
        [ngClass]="{ 
          'rounded-tl-2xl': $first, 
          'rounded-tr-xl': $last,
          'text-right': column.key === 'roamingFee' 
        }"
      >
        {{ column.title }}
      </th>
      }
    </tr>
  </thead>
  <tbody>
    @for (item of table.data; track $index) {
      <tr (click)="isMobile && onClick(item.msisdn)">
        @for (column of columns; track column.key) {
          @switch (true) {
            @case (column.isShowCurrency) {
              <td class="text-black text-sm lg:text-base font-bold text-right">{{item[column.key] | localCurrency: 'đ'}}</td>
            }
            @case (column.isMsisdn) {
              <td class="text-black text-sm lg:text-base font-bold whitespace-nowrap">{{item[column.key] | phoneNumber: ' '}}</td>
            }
            @case (column.isShowListLocation) {
              <td class="text-black text-sm lg:text-base">
                <div class="flex items-center gap-1">
                  <p 
                    class="line-clamp-1" 
                    [ngClass]="item.dwType !== area.ALL && item.dwType !== area.KV1 && item.dwType !== area.KV2 ? 'w-[70%]' : ''"
                  >
                    {{item[column.key]}}
                  </p>
                  <div nz-tooltip [nzTooltipTitle]="areaInfoTpl" nzTooltipPlacement="bottomCenter" class="py-2 cursor-pointer w-4">
                    <img src="./assets/icons/question.svg" alt=""/>
                  </div>
                  <ng-template #areaInfoTpl>
                    @if (item.dwType === area.ALL) {
                      <p class="text-white font-semibold text-xs">Tất cả các tỉnh thành</p>
                    } @else {
                      <p class="text-white font-semibold text-xs mb-1">Khu vực {{item.dwType}} MobiFone</p>
                      <ul class="list-disc pl-5 text-white font-semibold text-xs">
                        <li *ngFor="let item of showDetailArea(item.dwTypeName)">{{ item }}</li>
                      </ul>
                    }
                  </ng-template>
                </div>
              </td>
            }
            @case (!item[column.key]) {
              <td>
                <button nz-button nzSize="large" class="bg-blue-600 text-white text-sm font-medium rounded-lg w-full" (click)="onClick(item.msisdn)">Chọn</button>
              </td>
            }
            @default {
              <td class="text-black text-sm lg:text-base" [ngClass]="column.isBold ? 'font-bold' : ''">{{item[column.key]}}</td>
            }
          }
        }
      </tr>
    }
  </tbody>
</nz-table>

<ng-template #noResult>
  <p class="text-black text-base font-medium">Không tìm thấy bản ghi!</p>
</ng-template>