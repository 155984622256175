export const USERNAME_REGEX =
  /^(?=[a-zA-Z0-9._]{8,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/;
export const PASSWORD_REGEX =
  /^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#&()–{}:;',?/*~$^+=<>]).{6,})?$/;
export const PHONE_REGEX = /^0[1-9]\d{8}$/;
export const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const NUMBER_REGEX = /^[0-9]+$|^$/;
export const IDENTITY_CARD_REGEX = /(^[a-zA-Z0-9]{8}$)|(^[a-zA-Z0-9]{9}$)|(^[a-zA-Z0-9]{12}$)/;
export const DECIMAL_REGEX = /^\d*\.?\d*$/;
export const DECIMAL_2_PLACES_REGEX = /^(?:(\d{0,5}(?:\.\d))|(\d{0,7}))?$/; // cân nặng đc nhập 7 kí tự, nếu có dấu . thì đc nhập 1 kí tự sau dấu . (Dũng test yêu cầu sửa)
export const PERCENTAGE_REGEX =
  /^100(\.0{0,2})? *%?$|^\d{1,2}(\,\d{1,2})? *%?$/;
export const EXCLUDE_SPECIAL_CHARACTERS = /^[^$&+:;=?@#|'<>^*%!]*$/;
export const CODE_REGEX = /^[a-zA-Z0-9_]*$/;
export const UNSIGNED_NAME = /[A-Za-z0-9 ]/;

export const SERVICE_CODE_REGEX = /^[A-Z0-9-_.]+$/;

export const FRACTION_REGEX = /^[0-9\/]$/; // nhập 0-> 9 và dấu \ /
export const FRACTION_REGEX_2 = /^[0-9,]+$/; // nhập 0-> 9 và dấu phẩy

export const IDENTITY_AND_PASSPOST_REGEX = /^(?:\d{12}|[A-Z]{1,2}\d{6,9})$/;

//datetime regex
export const DATE_TIME_REGEX =
  /^([1-9]|([012][0-9])|(3[01]))\/([0]{0,1}[1-9]|1[012])\/\d\d\d\d (20|21|22|23|[0-1]?\d):[0-5]?\d:[0-5]?\d$/;
//datetime2 no second regex
export const DATE_TIME_NO_SECOND_REGEX =
  /^([1-9]|([012][0-9])|(3[01]))\/([0]{0,1}[1-9]|1[012])\/\d\d\d\d\s([0-1]?[0-9]|2?[0-3]):([0-5]\d)$/;
//date regex
export const DATE_REGEX =
  /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;

export const NAME_ETHNIC =
  /^[a-zỳọáầảấờễàạằệếýộậốũứĩõúữịỗìềểẩớặòùồợãụủíỹắẫựỉỏừỷởóéửỵẳẹèẽổẵẻỡơôưăêâđA-ZỲỌÁẦẢẤỜỄÀẠẰỆẾÝỘẬỐŨỨĨÕÚỮỊỖÌỀỂẨỚẶÒÙỒỢÃỤỦÍỸẮẪỰỈỎỪỶỞÓÉỬỴẲẸÈẼỔẴẺỠƠÔƯĂÊÂĐ0-9' ]*$/;
export const NAME_NATION =
  /^[a-zỳọáầảấờễàạằệếýộậốũứĩõúữịỗìềểẩớặòùồợãụủíỹắẫựỉỏừỷởóéửỵẳẹèẽổẵẻỡơôưăêâđA-ZỲỌÁẦẢẤỜỄÀẠẰỆẾÝỘẬỐŨỨĨÕÚỮỊỖÌỀỂẨỚẶÒÙỒỢÃỤỦÍỸẮẪỰỈỎỪỶỞÓÉỬỴẲẸÈẼỔẴẺỠƠÔƯĂÊÂĐ0-9- ]*$/;
export const NAME_Vi =
  /^[a-zỳọáầảấờễàạằệếýộậốũứĩõúữịỗìềểẩớặòùồợãụủíỹắẫựỉỏừỷởóéửỵẳẹèẽổẵẻỡơôưăêâđA-ZỲỌÁẦẢẤỜỄÀẠẰỆẾÝỘẬỐŨỨĨÕÚỮỊỖÌỀỂẨỚẶÒÙỒỢÃỤỦÍỸẮẪỰỈỎỪỶỞÓÉỬỴẲẸÈẼỔẴẺỠƠÔƯĂÊÂĐ0-9-(),. ]*$/;
export const NAME_En = /^[a-zA-Z '-.‘’]+$/;

export const NAME_VN =
  /^[a-zỳọáầảấờễàạằệếýộậốũứĩõúữịỗìềểẩớặòùồợãụủíỹắẫựỉỏừỷởóéửỵẳẹèẽổẵẻỡơôưăêâđA-ZỲỌÁẦẢẤỜỄÀẠẰỆẾÝỘẬỐŨỨĨÕÚỮỊỖÌỀỂẨỚẶÒÙỒỢÃỤỦÍỸẮẪỰỈỎỪỶỞÓÉỬỴẲẸÈẼỔẴẺỠƠÔƯĂÊÂĐ0-9 ]*$/;
export const ONE_DIGIT_DECIMAL_NUMBER = /^\d*\.?\d{0,1}$/g; //Dùng chỉ nhập được 1 số sau giấu chấm
export const TWO_DIGIT_DECIMAL_NUMBER = /^\d*\.?\d{0,2}$/g; //Dùng chỉ nhập được 2 số sau giấu chấm
