import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouterNavigateService extends Router {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  override navigate(routes: string[], extras?: NavigationExtras): Promise<boolean> {
    const rf = this.activatedRoute.snapshot.queryParams['rf'];
    return this.router.navigate(routes, { 
      ...extras,
      queryParams: { 
        ...extras?.queryParams,
        rf
      }
    });
  }
}
