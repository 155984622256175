import { Pipe, PipeTransform } from '@angular/core';
import { NumberUtil } from 'src/app/core/utils';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

  transform(value: string, format?: string): string {
    return `${NumberUtil.toPhoneNumber(value, format)}`;
  }

}
