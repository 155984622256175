<div class="bg-gray-100" [ngClass]="{ 'fixed top-0 w-screen z-50': !isShowStep }">
  <app-header></app-header>
</div>
<div class="bg-gray-50" [ngClass]="{ 'pt-[72px] lg:pt-[78px]': !isShowStep }">
  <router-outlet></router-outlet>
</div>
<div class="bg-gray-800">
  <app-footer></app-footer>
</div>
<a href="tel:9090" nz-button class="flex items-center justify-center bg-blue-600 border-none h-16 w-16 rounded-full fixed bottom-20 right-5 shadow-lg">
  <img src="./assets/icons/headphone-btn.svg" alt="" width="32px" height="32px"/>
</a>