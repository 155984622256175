import { IRadioOptions } from 'src/app/shared/controls/radio-group/radio-group.component';
import { DeliveryType } from 'src/app/shared/enums/order/delivery.enum';
import { PaymentChannel } from 'src/app/shared/enums/order/payment.enum';
import { SimType } from 'src/app/shared/enums/order/subs.enum';

export const SIM_TYPE_OPTIONS: IRadioOptions[] = [
  {
    label: 'SIM Vật lý',
    value: SimType.physical,
  },
  // {
  //   label: 'eSIM',
  //   value: SimType.eSim
  // }
];

export const DELIVERY_TYPE_SIM_PHY_OPTIONS: IRadioOptions[] = [
  {
    label: 'Nhận SIM & Hòa mạng tại điểm giao dịch MobiFone',
    value: DeliveryType.atStore,
  },
  {
    label: 'Nhận SIM & Hòa mạng tại nhà',
    value: DeliveryType.atHome,
  },
];

export const DELIVERY_TYPE_ESIM_OPTIONS = [
  {
    label: 'Nhận QR Code',
    value: DeliveryType.qrCode,
  },
  {
    label: 'Nhận SIM & Hòa mạng tại điểm giao dịch MobiFone',
    value: DeliveryType.atStore,
  },
];

export const DELIVERY_TYPE_POST_PAY_SIM_PHY_OPTIONS = [
  {
    label: 'Nhận SIM & Hòa mạng tại điểm giao dịch MobiFone',
    value: DeliveryType.atStore,
  },
];

export const PAYMENT_PHY_COD_OPTIONS = [
  {
    label: 'Ví MoMo',
    value: PaymentChannel.momo,
    prefix: './assets/images/momo.webp',
  },
  {
    label: 'Thanh toán online qua cổng VNPAY',
    value: PaymentChannel.vnpay,
    prefix: './assets/images/vnpay.webp',
  },
  // {
  //   label: 'Ví MobiFone Money',
  //   value: PaymentChannel.mbfm,
  //   prefix: './assets/images/mbf-money.webp'
  // }
];

export const PAYMENT_DGD_OPTIONS = [
  // {
  //   label: 'Thanh toán tại điểm giao dịch',
  //   value: PaymentChannel.partner,
  //   prefix: './assets/images/cod.webp'
  // },
  {
    label: 'Ví MoMo',
    value: PaymentChannel.momo,
    prefix: './assets/images/momo.webp',
  },
  {
    label: 'Thanh toán online qua cổng VNPAY',
    value: PaymentChannel.vnpay,
    prefix: './assets/images/vnpay.webp',
  },
  // {
  //   label: 'Ví MobiFone Money',
  //   value: PaymentChannel.mbfm,
  //   prefix: './assets/images/mbf-money.webp'
  // }
];

export const PAYMENT_ESIM_QR_OPTIONS = [
  {
    label: 'Ví MoMo',
    value: PaymentChannel.momo,
    prefix: './assets/images/momo.webp',
  },
  {
    label: 'Thanh toán online qua cổng VNPAY',
    value: PaymentChannel.vnpay,
    prefix: './assets/images/vnpay.webp',
  },
  // {
  //   label: 'Ví MobiFone Money',
  //   value: PaymentChannel.mbfm,
  //   prefix: './assets/images/mbf-money.webp'
  // }
];

export const RE_PAYMENT_OPTIONS = [
  {
    label: 'Ví MoMo',
    value: PaymentChannel.momo,
    prefix: './assets/images/momo.webp',
  },
  {
    label: 'Thanh toán online qua cổng VNPAY',
    value: PaymentChannel.vnpay,
    prefix: './assets/images/vnpay.webp',
  },
  // {
  //   label: 'Ví MobiFone Money',
  //   value: PaymentChannel.mbfm,
  //   prefix: './assets/images/mbf-money.webp'
  // }
];

export const PAYMENT_OPTIONS = [
  {
    label: 'VNPay QR',
    value: PaymentChannel.vnpay_qr,
    prefix: './assets/images/vnpay.webp',
  },
  {
    label: 'Thẻ ATM nội địa',
    value: PaymentChannel.vnpay_debit,
    prefix: './assets/images/debit.webp',
  },
  {
    label: 'Thẻ thanh toán quốc tế',
    value: PaymentChannel.vnpay_visa,
    prefix: './assets/images/visa.webp',
  },
  {
    label: 'Ví MoMo',
    value: PaymentChannel.momo,
    prefix: './assets/images/momo.webp',
  },
];
