import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzSelectComponent } from 'ng-zorro-antd/select';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ]
})
export class SelectComponent implements ControlValueAccessor {
  @ViewChild(NzSelectComponent, { static: true }) nzSelect!: NzSelectComponent;

  @Input() formControlName: string = '';
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() allowClear = false;
  @Input() size: 'small' | 'default' | 'large' = 'default';
  @Input() isMulti = false;
  @Input() options: any[] | null = [];
  @Input() showSearch = false;
  @Input() isLoading: boolean | null = false;
  @Input() isHasError = false;
  value!: string;

  _onChange!: (_: any) => void;
  _onTouched!: (_: any) => void;

  constructor() { }
  
  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange(event: any): void {
    if (this._onChange) {
      this.value = event;
      this._onChange(event);
    }
  }

  focusAndScroll() {
    if (this.nzSelect) {
      this.nzSelect.focus();
    }
  }
}
