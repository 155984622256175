import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {BreakpointObserver} from "@angular/cdk/layout";
import { RouterNavigateService } from 'src/app/core/services/router-navigate.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationComponent implements OnChanges {
  @Input() total = 0;
  @Input() index = 1;
  @Input() size = 10;
  @Input() sizeOptions = [10, 20, 30, 40, 50];
  @Output() indexChange = new EventEmitter<number>();
  @Output() sizeChange = new EventEmitter<number>();
  ranges = [0, 0];
  pages: any[] = [];

  scollHeight = 0;

  constructor(
    private cdr: ChangeDetectorRef,
    private breakpointOserver: BreakpointObserver,
    private router: RouterNavigateService
  ) {
    this.handleBreakpointScrollOnPaging();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { total, size, index } = changes;
    if (total) {
      this.onTotalChange(this.total);
    }
    if (index || size || total) {
      this.ranges = [
        this.total ? (this.index - 1) * this.size + 1 : 0,
        Math.min(this.index * this.size, this.total)
      ];
      this.buildIndexes();
    }
  }

  handleBreakpointScrollOnPaging() {
    this.breakpointOserver.observe(['(min-width: 1280px)']).subscribe(result => {
      if (this.isSimFengSuiUrl) {
        this.scollHeight = result.matches ? 1000 : 600;
      } else {
        this.scollHeight = result.matches ? 300 : 0;
      }
    })
  }

  get isSimFengSuiUrl() {
    const url = this.router.url;
    return url.includes('sim-phong-thuy');
  }

  onIndexChange(index: number): void {
    const lastIndex = this.getLastIndex(this.total, this.size);
    const validIndex = this.validatePageIndex(index, lastIndex);
    if (validIndex !== this.index) {
      this.index = validIndex;
      this.buildIndexes();
      this.indexChange.emit(this.index);
    }
    window.scroll({ top: this.scollHeight, behavior: 'auto' });
  }

  onTotalChange(total: number): void {
    const lastIndex = this.getLastIndex(total, this.size);
    if (this.index > lastIndex) {
      this.onIndexChange(lastIndex);
      this.cdr.markForCheck();
    }
  }

  prePage(): void {
    this.onIndexChange(this.index - 1);
    window.scroll({ top: this.scollHeight, behavior: 'auto' });
  }

  nextPage(): void {
    this.onIndexChange(this.index + 1);
    window.scroll({ top: this.scollHeight, behavior: 'auto' });
  }

  firstPage(): void {
    this.onIndexChange(1);
  }

  private getLastIndex(total: number, size: number): number {
    return Math.ceil(total / size);
  }

  private validatePageIndex(value: number, lastIndex: number): number {
    if (value > lastIndex) {
      return lastIndex;
    } else if (value < 1) {
      return 1;
    } else {
      return value;
    }
  }

  private buildIndexes(): void {
    const lastIndex = this.getLastIndex(this.total, this.size);
    this.pages = this.getListOfPageItem(this.index, lastIndex);
  }

  private getListOfPageItem(
    pageIndex: number,
    lastIndex: number
  ): Array<Partial<any>> {
    const concatWithPrevNext = (listOfPage: Array<Partial<any>>) => {
      const prevItem = {
        type: 'prev',
        disabled: pageIndex === 1
      };
      const nextItem = {
        type: 'next',
        disabled: pageIndex === lastIndex
      };
      const firstPageItem = {
        type: 'first',
        disabled: pageIndex === 1
      };
      return [prevItem,firstPageItem, ...listOfPage.slice(-6) ,nextItem];
    };
    const generatePage = (start: number, end: number): Array<Partial<any>> => {
      const list = [];
      for (let i = start; i <= end; i++) {
        list.push({
          index: i,
          type: 'page'
        });
      }
      return list;
    };
    if (lastIndex <= 5) {
      return concatWithPrevNext(generatePage(1, lastIndex));
    } else {
      const generateRangeItem = (selected: number, last: number) => {
        let listOfRange: any[] = [];
        if (selected < 5) {
          const maxLeft = selected === 4 ? 6 : 5;
          listOfRange = generatePage(selected === 4 ? 2 : 1, maxLeft);
        } else if (selected <= last - 3) {
          listOfRange = generatePage(selected - 2, selected + 2);
        } else {
          const minRight = selected === last - 3 ? last - 5 : last - 4;
          listOfRange = generatePage(minRight, last);
        }
        return listOfRange;
      };
      return concatWithPrevNext(generateRangeItem(pageIndex, lastIndex));
    }
  }
}
