export const environment = {
  production: false,
  apiUrl: 'https://csapi.ospgroup.vn',
  u: 'api_webmobifone',
  p: '12345678',
  publicKey: `
    -----BEGIN PUBLIC KEY-----
    MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAzTiWW8fbPSTrpxIVPGEL
    PFLc8zhPZXclEjGO3IpJzd580ihRRWVjoxCl1jLZZmhEl/w6ExbwlpvS7nmyKHYh
    OYBTRhBXcEBb4vUNvekHj8LeTahMMEPaSYOWTBb0YwP7uc+KUexE/fsu+dFXTY+N
    QOAAKLvLC19kTUIKZ+0ibDMswhFuvT3Vhtwd/GK9Vq5fkHEKxffsjBtoWoHMtgC5
    r/88YhlNTptEDlfD1zgzVTACr8iWgIDqqE5afPq3PS0KeOXoD8yYFe5MOVY3SPUv
    QR2FDoLdaT6wx9ZuZHeIhNTl6dTUb+jQ/25trxfpw3VWD2/kf7ACEyLYDFjqTSI+
    4wIDAQAB
    -----END PUBLIC KEY-----
  `
};
