import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'options',
})
export class OptionsPipe implements PipeTransform {

  transform(data: any[]): any[] | null {
    if (data) {
      return data.map(item => {
        return {
          label: item.name,
          value: item.id
        }
      });
    }
    return null;
  }

}
