export const ROUTER = {
  HOMEPAGE: '',
  SIM_NORMAL: 'sim-thuong',
  SIM_DATA: 'sim-data',
  SIM_COMMIT: 'sim-cam-ket',
  SIM: 'sim',
  MSISDN_DETAIL: 'thong-tin-thue-bao',
  ORDER_BOOKING_MSISDN: 'dang-ky-thue-bao',
  SHOP_LOCATION: 'diem-giao-dich',

  // Tra cứu đơn hàng
  ORDER_LOOKUP: 'tra-cuu-don-hang',
  ORDER_RESULT: 'ket-qua-don-hang',
  BOOKING_DETAIL: 'thong-tin-phieu-giu-so',
  ORDER_DETAIL: 'thong-tin-don-hang',

  WAP_MOBIFONE: 'my-mobifone',

  PAGE_NOT_FOUND: '**'
};