import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-card-package',
  templateUrl: './card-package.component.html',
  styleUrl: './card-package.component.scss'
})
export class CardPackageComponent {
  @Input() package: any;
  @Input() allowSelect = false;
  @Input() isShowButton = true;
  @Output() onItemSelect = new EventEmitter();

  @ViewChild('packageInfo') packageInfo: TemplateRef<any>;

  constructor(private modalService: NzModalService) {}

  seeMore(event: MouseEvent) {
    event.stopPropagation();
    this.modalService.create({
      nzTitle: '',
      nzContent: this.packageInfo,
      nzFooter: null,
      nzBodyStyle: { padding: '0' },
      nzClosable: false,
      nzCentered: true,
    })
  }

  onClick(isFromModal?: boolean) {
    if (isFromModal) {
      this.modalService.closeAll();
    }
    this.onItemSelect.emit({ ...this.package, isFromModal });
  }

  closeModal() {
    this.modalService.closeAll();
  }
}
