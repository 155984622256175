import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MAIN_MENU, SUB_MENU } from 'src/app/shared/constants/menu.constant';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent {

  readonly mainMenu = MAIN_MENU;
  readonly subMenu = SUB_MENU;

  constructor(
    private activatedRoute: ActivatedRoute
  ) {
  }

  get queryParams() {
    const rf = this.activatedRoute.snapshot.queryParams['rf'];
    return { rf };
  }
}
