<div
  class="card bg-card-wave bg-contain bg-no-repeat bg-bottom rounded-2xl shadow"
  [ngClass]="{
    'cursor-pointer border-4 hover:border-4 hover:border-blue-default': allowSelect,
    ' border-blue-default': package.selected,
    'border': !package.selected
  }"
  (click)="!isShowButton && onClick()"
>
  <div class="border-b border-gray-300 p-4">
    <div class="flex justify-between items-center">
      <div>
        <p class="text-black text-base font-bold">{{ package.packageName }}</p>
        <p class="text-gray-400 text-xs">
          Chu kỳ sử dụng {{ package.period }} ngày
        </p>
      </div>
      @if (allowSelect) {
        <div
          class="w-5 h-5 border rounded-full relative"
          [ngClass]="
            package.selected
              ? 'border-blue-default bg-white'
              : 'border-[#DDDDE3] bg-#F5F5FA'
          "
        >
          @if (package.selected) {
            <div class="w-3 h-3 bg-blue-default rounded-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"></div>
          }
      </div>
      }
    </div>
  </div>
  <div class="p-4">
    <p class="text-black font-bold">
      <span class="text-2xl">{{ package.minPrice | localCurrency : "đ" }}</span>
      <span class="text-light-neutral-50 text-base"> / tháng</span>
    </p>
    <div class="flex items-start gap-1 my-4 h-12">
      <img src="./assets/icons/tick-green-circle.svg" alt="" />
      <p class="text-gray-700 text-xs line-clamp-3">{{ package.description }}</p>
    </div>
    <div class="flex justify-center">
      <span
        class="text-blue-600 text-xs hover:underline cursor-pointer"
        [ngClass]="isShowButton ? 'mb-4' : ''"
        (click)="seeMore($event)"
      >
        Xem thêm
      </span>
    </div>
    @if (isShowButton) {
      <button
        nz-button
        nzSize="large"
        class="bg-blue-600 border-none text-white text-base w-full rounded"
        (click)="onClick()"
      >
        Chọn
      </button>
    }
  </div>
</div>

<ng-template #packageInfo>
  <div class="bg-blue-600 rounded-t-lg p-4 flex justify-between">
    <div class="w-6"></div>
    <p class="text-white text-center text-2xl font-semibold">
      Thông tin chi tiết
    </p>
    <button (click)="closeModal()" class="outline-none">
      <img src="./assets/icons/close-modal-x.svg" alt="" />
    </button>
  </div>
  <div
    class="bg-wave bg-[100%,100%] bg-no-repeat bg-bottom bg-white p-4 rounded-b-lg flex flex-col gap-4 items-center"
  >
    <div class="flex flex-col items-center justify-center min-h-[200px]">
      <div>
        <p class="text-black text-base">
          Gói cước
          <span class="font-semibold">{{ package.packageName }}</span> với
          <span class="font-semibold">{{
            package.minPrice | localCurrency : "đ"
          }}</span>
          sử dụng trong
          <span class="font-semibold">{{ package.period }} ngày:</span>
        </p>
        <div [innerHTML]="package.description | safeHtml"></div>
      </div>
    </div>
    <button
      nz-button
      nzSize="large"
      class="bg-blue-600 border-none text-white text-base w-1/3 rounded"
      (click)="onClick(true)"
    >
      Chọn
    </button>
  </div>
</ng-template>
