export enum BookingStatus {
  active = 0,
  processed = 1,
  rejected = 2,
  roamed = 3,
  expired = 4,
} //Trạng thái đơn hàng (0- Đang hiệu lực, 1- Đã xử lý, 2- Từ chối đấu nối, 3- Đã hoà mạng, 4- Hết hiệu lực)

export const getBookingStatusStr = (status: BookingStatus) => {
  switch (status) {
    case BookingStatus.active:
      return 'Đang hiệu lực';
    case BookingStatus.processed:
      return 'Đã xử lý';
    case BookingStatus.rejected:
      return 'Từ chối đấu nối';
    case BookingStatus.roamed:
      return 'Đã hoà mạng';
    case BookingStatus.expired:
      return 'Hết hiệu lực';
  }
};

export const getBookingStatusColor = (type: BookingStatus) => {
  switch (type) {
    case BookingStatus.active:
      return '#F79009';
    case BookingStatus.processed:
      return '#12B76A';
    case BookingStatus.roamed:
      return '#1570EF';
    default:
      return '#F04438';
  }
};
