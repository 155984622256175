<footer class="container mx-auto py-6">
  <div class="grid grid-cols-4">
    <div class="col-span-4 lg:col-span-1">
      <div class="mb-2">
        <p class="text-white text-base font-medium mb-1 text-center lg:text-left">TẢI ỨNG DỤNG MY MOBIFONE</p>
        <div class="flex items-center justify-center lg:justify-start gap-8">
          @for (item of downloadIcons; track $index) {
            <a [href]="item.link" target="_blank">
              <img [src]="item.src" alt="" />
            </a>
          }
        </div>
      </div>
      <div>
        <p class="text-white text-base font-medium mb-1 text-center lg:text-left">KẾT NỐI VỚI MOBIFONE</p>
        <div class="flex items-center justify-center lg:justify-start lg:grid lg:grid-cols-[28px_28px_28px] gap-x-10 gap-y-4">
          @for (item of connectIcons; track $index) {
            <a [href]="item.link" target="_blank">
              <img [src]="item.src" alt="" />
            </a>
          }
        </div>
      </div>
    </div>
    <div class="grid grid-cols-2 max-lg:gap-4 max-lg:mt-4 lg:flex justify-between col-span-4 lg:col-span-3 lg:ml-16 2xl:ml-40">
      <div class="col-span-1">
        <p class="text-white text-base font-medium mb-4">GIỚI THIỆU</p>
        <div class="flex flex-col">
          @for (item of introduceMbf; track $index) {
            <a [href]="item.link" target="_blank" class="text-white text-sm hover:underline">{{item.label}}</a>
          }
        </div>
      </div>
      <div class="lg:ml-5 2xl:ml-0 col-span-1">
        <p class="text-white text-base font-medium mb-4">HỖ TRỢ KHÁCH HÀNG</p>
        <div class="flex flex-col">
          @for (item of supportGuest; track $index) {
            <a [href]="item.link" target="_blank" class="text-white text-sm hover:underline">{{item.label}}</a>
          }
        </div>
      </div>
      <div class="col-span-1">
        <p class="text-white text-base font-medium mb-4">ĐIỀU KHOẢN & BẢO MẬT</p>
        <div class="flex flex-col">
          @for (item of privateAndPolicy; track $index) {
            <a [href]="item.link" target="_blank" class="text-white text-sm hover:underline">{{item.label}}</a>
          }
        </div>
      </div>
    </div>
  </div>
  <div class="grid grid-cols-4 border-t border-t-gray-500 mt-6 pt-6">
    <div class="col-span-4 lg:col-span-1">
      <p class="text-white text-sm font-medium mb-4 whitespace-nowrap">Tổng công ty Viễn Thông MobiFone</p>
      <div class="flex items-start gap-1">
        <img src="./assets/icons/location-white.svg" alt="" />
        <p class="text-white text-xs">Số 01 phố Phạm Văn Bạch, Phường Yên Hoà, Quận Cầu Giấy, TP. Hà Nội.</p>
      </div>
      <div class="flex items-start gap-1 my-1">
        <img src="./assets/icons/headphone.svg" alt="" />
        <p class="text-white text-xs">(+84-24) 3783 1800</p>
      </div>
      <div class="flex items-start gap-1">
        <img src="./assets/icons/fax.svg" alt="" />
        <p class="text-white text-xs">(+84-24) 3783 1734</p>
      </div>
    </div>
    <div class="col-span-4 lg:col-span-3 flex flex-col lg:flex-row lg:gap-10 xl:gap-[7.5rem] 2xl:gap-36 lg:ml-16 2xl:ml-40">
      <div class="mt-6 lg:mt-0 whitespace-nowrap">
        <p class="text-white text-sm font-medium mb-4">Tổng đài</p>
        <p class="text-white text-xs mb-1">
          <span class="text-blue-light-500 font-bold">9090</span>
          (Tổng đài hỗ trợ 24/7)
        </p>
        <p class="text-white text-xs">
          <span class="text-blue-light-500 font-bold">0931.000.666</span>
          (Miễn phí)
        </p>
      </div>
      <div class="mt-6 lg:mt-0">
        <div class="flex flex-col lg:flex-row justify-between lg:gap-10">
          <p class="text-white text-sm mb-4 w-full lg:w-[calc(100%-128px)]">Giấy chứng nhận đăng ký doanh nghiệp: Mã số doanh nghiệp: 0100686209, Đăng ký thay đổi lần thứ 10 ngày 10/03/2021, cấp bởi sở KHĐT Thành phố Hà Nội.</p>
          <div class="w-full lg:w-32 flex justify-center lg:justify-end items-start">
            <img src="./assets/images/bo-cong-thuong.webp" alt="Xác nhận bởi Bộ công thương" class="-ml-2" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="border-t border-t-gray-500 mt-6 pt-6">
    <p class="text-gray-500 text-sm text-center">© Copyright 2024 - MobiFone.</p>
  </div>
</footer>