@if(isOrderResult){
<div
  class="flex flex-col items-center mb-6 p-4 rounded-2xl text-center"
  [ngClass]="{
    'bg-[#FEF0C7]': !hasPaid && paymentChannel !== PaymentChannel.cod,
    'bg-blue-100': hasPaid || paymentChannel === PaymentChannel.cod
  }"
>
  <img
    [src]="
      hasPaid || paymentChannel === PaymentChannel.cod
        ? './assets/icons/check-circle.svg'
        : './assets/icons/x-circle.svg'
    "
    alt="Order status icon"
  />
  <p class="text-xl font-semibold">
    {{
      hasPaid || paymentChannel === PaymentChannel.cod
        ? "Đặt hàng thành công!"
        : "Đơn hàng chưa được thanh toán"
    }}
  </p>
  @if (orderInfo) {
  <p class="text-base font-medium text-gray-700">
    {{
      hasPaid || paymentChannel === PaymentChannel.cod
        ? "Cảm ơn quý khách đã lựa chọn dịch vụ SIM số của MobiFone!"
        : "Quý khách vui lòng thanh toán trước " + orderInfo.expDate
    }}
  </p>
  }
</div>
}

<div
  class="flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-10 justify-between lg:items-start"
>
  <div class="flex flex-col space-y-4 flex-1">
    @if(isDeliveryAtHome){
    <div class="flex flex-col space-y-4">
      <p class="text-base font-bold">Phương thức vận chuyển</p>
      <div class="flex flex-row rounded-lg bg-white p-4 shadow-sm items-center">
        <img
          src="./assets/images/lookup-order/logo_ghtk.webp"
          alt="Delivery - GHTK"
          width="40px"
          height="40px"
        />
        <div class="flex-col ml-2 text-base justify-between">
          <p class="lg:hidden">Giao hàng tại nhà</p>
          <p class="lg:hidden">Giao hàng tiết kiệm</p>
          <p class="hidden lg:block">Giao hàng tại nhà - Giao hàng tiết kiệm</p>
        </div>
      </div>
    </div>
    } @if (deliveredAddressSection && deliveredAddressSection.value.length) {
    <div class="flex flex-col space-y-4">
      <p class="text-base font-bold">Địa chỉ nhận hàng</p>
      <div
        [ngClass]="{ 'flex-col': !isESIM, 'flex-row': isESIM }"
        class="flex rounded-lg bg-white p-4 text-base shadow-sm justify-between"
      >
        @if(isESIM){
        <p>{{ customerEmailField.label }}</p>
        <p class="text-blue-600">{{ customerEmailField.value }}</p>
        } @else{
        <p>{{ customerName }} - {{ customerPhoneNumber }}</p>
        <p>{{ customerAddress }}</p>
        }
      </div>
    </div>
    }
    <div class="flex flex-col space-y-4">
      <p class="text-base font-bold">Phương thức thanh toán</p>
      <div
        class="flex flex-row rounded-lg bg-white p-4 shadow-sm justify-between"
      >
        @if(!hasPaid && paymentChannel !== PaymentChannel.cod &&
        !isOrderCancelled){
        <div class="flex flex-row flex-1 items-center mr-4">
          <img
            [src]="getCurrentOnlinePaymentChannel().prefix"
            alt="Payment channel icon"
          />
          <p class="ml-2 text-base">
            {{ getCurrentOnlinePaymentChannel().label }}
          </p>
        </div>
        <div class="flex flex-row flex-1 items-center justify-end">
          <p class="text-sx mr-1 text-gray-500">Thay đổi</p>
          <div class="flex flex-row">
            <div class="flex">
              <div
                *ngFor="
                  let company of getOtherOnlinePaymentChannels();
                  let i = index
                "
                [ngStyle]="{ 'z-index': i + 1 }"
                class="w-8 h-8 ml-5 absolute rounded-full border border-gray-300 overflow-hidden"
                [ngClass]="{
                'ml-5 absolute': i > 0,
              }"
              >
                <img
                  [src]="company.prefix"
                  alt="{{ company.label }}"
                  class="object-cover object-center"
                />
              </div>
            </div>
            <button (click)="switchOnlinePaymentChannel()">
              <img
                src="./assets/icons/switch.svg"
                alt="Switch icon"
                class="ml-6"
              />
            </button>
          </div>
        </div>

        } @else {
        <div class="flex flex-row items-center">
          <img
            [src]="choosePaymentChannelLogo(paymentChannel)"
            alt="Payment channel icon"
          />
          <p class="ml-2 text-base">
            {{ paymentChannelStr }}
          </p>
        </div>
        }
      </div>
    </div>
    <div class="flex flex-col space-y-4">
      <div class="flex flex-row justify-between items-center gap-4">
        <p class="text-base font-bold w-[104px] whitespace-nowrap">
          Mã đơn hàng
        </p>
        <div
          class="flex flex-row items-center justify-end gap-2 w-[calc(100%-120px)]"
        >
          <p
            class="text-base font-bold text-blue-600 text-ellipsis overflow-hidden"
          >
            {{ orderCode }}
          </p>
          <button
            class="bg-[#0000001A] px-3 py-1 text-[#27272A] text-[12px] rounded self-center"
            (click)="copyToClipboard(orderCode, 'orderCode')"
            nz-tooltip
            [nzTooltipTitle]="'Đã sao chép!'"
            [nzTooltipPlacement]="'bottom'"
            [nzTooltipTrigger]="'click'"
            [nzTooltipVisible]="isOrderCodeCopied"
          >
            Copy
          </button>
        </div>
      </div>
      <ul class="flex flex-col rounded-lg bg-white p-4 shadow-sm space-y-2">
        <li
          class="flex flex-row text-base justify-between"
          *ngFor="let info of paymentInfo"
        >
          <p class="text-left">{{ info.label }}</p>
          <p
            [ngStyle]="
              info.isOrderStatus ? { color: info.orderStatusColor } : {}
            "
            class="text-right"
          >
            {{ info.value }}
          </p>
        </li>
      </ul>
    </div>
  </div>
  <div class="flex flex-col rounded-lg bg-white p-4 shadow-sm flex-1">
    <p class="text-left text-base font-bold mb-4">Đơn mua</p>
    <ul class="flex flex-col space-y-2">
      <li
        class="flex flex-row text-base justify-between"
        [ngClass]="{
          'border-b border-dashed border-gray-300 pb-2 mb-2': info.hasDashedLine
        }"
        *ngFor="let info of invoice"
      >
        <p
          [ngClass]="{
            'font-bold': info.isBoldRow
          }"
          class="text-left text-base"
        >
          {{ info.label }}
        </p>
        <div class="flex flex-row items-center">
          @if (info.isCode) {
          <a
            [routerLink]="['/' + ROUTER.ORDER_LOOKUP, ROUTER.BOOKING_DETAIL]"
            [queryParams]="{ code: info.value }"
            class="text-right text-base hover:underline"
            [ngClass]="{
                  'font-bold': info.isBoldValue || info.isBoldRow || info.isCode,
                  'text-lg text-blue-600 ': info.isCode,
                }"
          >
            {{ info.value }}
          </a>
          } @else {
          <p
            class="text-right text-base"
            [ngClass]="{
                  'font-bold': info.isBoldValue || info.isBoldRow || info.isCode,
                }"
          >
            {{ info.value }}
          </p>
          }
          <button
            *ngIf="info.isCode"
            class="bg-[#0000001A] px-3 py-0.5 text-[#27272A] text-[12px] rounded ml-2"
            (click)="copyToClipboard(info.value, 'bookingCode')"
            nz-tooltip
            [nzTooltipTitle]="'Đã sao chép!'"
            [nzTooltipPlacement]="'bottom'"
            [nzTooltipTrigger]="'click'"
            [nzTooltipVisible]="isBookingCodeCopied"
          >
            Copy
          </button>
        </div>
      </li>
    </ul>
  </div>
</div>
<p class="my-4 lg:my-6 text-[#7A869A] text-center text-base italic">
  Quý khách vui lòng lưu lại “Mã đơn hàng” và “Mã giữ số” để tra cứu và thực
  hiện hòa mạng.
</p>
<div class="flex flex-col sm:flex-row gap-2 justify-center">
  <button
    class="text-base rounded border border-blue-600 px-1 py-2 w-full sm:w-60 lg:mt-4"
    [ngClass]="
      hasPaid ||
      orderInfo.orderInfo.paymentChannel === PaymentChannel.cod ||
      isOrderCancelled
        ? 'bg-blue-600 text-white'
        : ' bg-white text-blue-600'
    "
    (click)="navigateHomePage()"
  >
    {{
      hasPaid || orderInfo.orderInfo.paymentChannel === PaymentChannel.cod
        ? "Chọn thêm SIM"
        : "Trang chủ"
    }}
  </button>
  @if (!isOrderCancelled) { @if (!hasPaid) {
  <button
    class="text-base rounded border border-blue-600 bg-blue-600 text-white px-2 py-3 w-full sm:w-60 lg:mt-4"
    (click)="orderPayment(orderInfo)"
  >
    Thanh toán
  </button>
  } @if (isAllowCancelOrder) {
  <button
    class="text-base rounded border border-red-error-600 bg-red-error-600 text-white px-2 py-3 w-full sm:w-60 lg:mt-4"
    (click)="cancelOrder()"
  >
    Hủy đơn hàng
  </button>
  } }
</div>

<ng-template #switchPaymenModal>
  <div class="p-4 rounded-t-lg flex items-start justify-between gap-4">
    <div class="w-6"></div>
    <p class="text-black text-xl text-center font-bold">
      Phương thức thanh toán
    </p>
    <button (click)="closeModal()" class="outline-none">
      <span
        nz-icon
        nzType="close-circle"
        nzTheme="outline"
        class="text-2xl text-gray-300"
      ></span>
    </button>
  </div>
  <div class="p-4">
    <form>
      <app-radio-group
        [(ngModel)]="paymentChannel"
        name="paymentChannel"
        [options]="paymentOptions"
        display="vertical"
        groupClass="flex gap-2"
        labelClass="flex items-center border px-4 py-3 rounded-lg w-full m-0 bg-white"
      >
      </app-radio-group>
      <div class="flex justify-center mt-4">
        <button
          nz-button
          nzSize="large"
          type="button"
          class="text-base rounded border border-blue-600 bg-blue-600 text-white w-2/3 lg:w-1/2"
          (click)="orderSwitchPayment()"
        >
          Thanh toán
        </button>
      </div>
    </form>
  </div>
</ng-template>
