import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MsisdnType } from '../../enums/msisdn-type.enum';
import { LocalCurrencyPipe } from '../../pipes/currency.pipe';

@Component({
  selector: 'app-sim-card-info',
  templateUrl: './sim-card-info.component.html',
  styleUrl: './sim-card-info.component.scss'
})
export class SimCardInfoComponent implements OnChanges {
  @Input() msisdnInfo: any;
  @Input() packageSelected: any;
  msisdnSubInfo: any;
  readonly msisdnType = MsisdnType;

  constructor(
    private localCurrency: LocalCurrencyPipe
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (('msisdnInfo' in changes && this.msisdnInfo) || ('packageSelected' in changes && this.packageSelected)) {
      this.generateMsisdnInfo();
    }
  }

  generateMsisdnInfo() {
    this.msisdnSubInfo = [
      {
        label: 'Loại số',
        content: this.msisdnInfo.groupName
      },
      {
        label: 'Khu vực hòa mạng',
        content: this.msisdnInfo.regionStr
      },
      {
        label: 'Phí dịch vụ',
        content: this.msisdnInfo.monthFee
      },
      {
        label: 'Phí giữ số',
        content: 'Miễn phí'
      },
      {
        label: 'Phí hòa mạng',
        content: this.localCurrency.transform(this.msisdnInfo.roamingFee, 'đ')
      },
      {
        label: 'Giá gói cước',
        content: this.packageSelected?.minPrice ? this.localCurrency.transform(this.packageSelected.minPrice, 'đ') : '-'
      }
    ]
  }
}
