import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';
import { environment } from 'src/environments/environment';
import { formatDate } from '@angular/common';
import { JSEncrypt } from 'jsencrypt';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseApiService {

  login(): Observable<any> {
    const encryptString = this.encryptData(`${environment.u}osp${environment.p}osp${formatDate(Date.now(), 'yyyyMMddHHmmss', 'vi')}`, environment.publicKey);
    const body = {
      userType: 'MEM',
      encryptString
    };
    return this.http.post(`${this.endpoint}/users/login`, body);

  }

  encryptData(data: string, publicKeyPem: string): string {
    const encryptor = new JSEncrypt();
    encryptor.setPublicKey(publicKeyPem);
  
    const encrypted = encryptor.encrypt(data);
    if (!encrypted) {
      throw new Error('Encryption failed.');
    }
  
    return encrypted;
  }
}



