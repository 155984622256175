import { NULL_STRING_PLACEHOLDER } from "../../constants/texts.constant";

export enum SubsStatus {
  notClassify = 0,
  inStock = 1, // Chưa được giữ bao giờ
  locked = 2, // Đang đấu giá
  holding = 3,
  roamed = 4,
  stopUsed = 5,
  availableInStock = 6, // Đã từng được giữ nhưng hết thời gian nên trở lại về kho
  notInStock =7, // Không còn tồn tại trong kho 100
}

export const getSubsStatusStr = (status: SubsStatus) => {
  switch (status) {
    case SubsStatus.notClassify:
      return 'Chưa phân loại';
    case (SubsStatus.inStock, SubsStatus.availableInStock):
      return 'Trong kho';
    case SubsStatus.locked:
      return 'Đang đấu giá';
    case SubsStatus.holding:
      return 'Đang giữ số';
    case SubsStatus.roamed:
      return 'Đã hoà mạng';
    case SubsStatus.stopUsed:
      return 'Ngừng sử dụng';
    case SubsStatus.notInStock:
      return 'Không còn tồn tại trong kho 100';
    default:
      return NULL_STRING_PLACEHOLDER;
  }
};

export enum SubsType {
  prepaid = 0,
  postpaid = 1,
}

export const getSubsTypeStr = (type: SubsType) => {
  return type === SubsType.prepaid ? 'Trả trước' : 'Trả sau';
};

export enum SimType {
  eSim = 'ESIM',
  physical = 'PHY',
}

export const getSimTypeStr = (type: SimType) => {
  return type === SimType.eSim ? 'eSIM' : 'SIM vật lý';
};
