import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService, NotificationService } from '../services';
import { LocalStorageUtil } from '../utils';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private refreshAttemptCount = 0;
  private readonly maxRefreshAttempts = 5;

  constructor(
    private authService: AuthService,
    private notification: NotificationService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let newReq: HttpRequest<any> = req.clone();
    if (LocalStorageUtil.getItem('accessToken')) {
      const token = LocalStorageUtil.getItem('accessToken');
      newReq = this.addTokenHeader(req, token);
    }
    if (!window.navigator.onLine) {
      return throwError(
        new HttpErrorResponse({
          error: 'Hệ thống có lỗi, bạn vui lòng thực hiện lại sau'
        })
      );
    }
    return next.handle(newReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          if (this.refreshAttemptCount < this.maxRefreshAttempts) {
            this.refreshAttemptCount++;
            return this.handle401Error(newReq, next);
          }
        }
        if ([0, 500].includes(error.status) || !navigator.onLine) {
          this.notification.open({
            type: 'error',
            content: 'Hệ thống có lỗi, bạn vui lòng thực hiện lại sau'
          });
        }

        return throwError(error);
      })
    );
  }

  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    localStorage.removeItem('accessToken');
    this.authService.login().pipe(
      switchMap(res => {
        if (res.data) {
          LocalStorageUtil.setItem('accessToken', res?.data?.accessTokenInfo?.accessToken);
          const authReq = this.addTokenHeader(request, res.data?.accessTokenInfo.accessToken);
          this.refreshAttemptCount = 0;
          return next.handle(authReq);
        }
        return throwError(request);
      }),
      catchError((err: any) => throwError(err))
    ).subscribe(() => {
      window.location.reload();
    });
    return next.handle(request)
  }

  private addTokenHeader(request: HttpRequest<any>,  token: any): HttpRequest<any> {
    return request.clone({
      headers: request.headers.set(
        'Authorization',
        `Bearer ${token}`
      )
    });
  }
}
