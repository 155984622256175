import { Component } from '@angular/core';
import { RouterNavigateService } from 'src/app/core/services/router-navigate.service';
import { ROUTER } from 'src/app/shared/constants';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrl: './page-not-found.component.scss'
})
export class PageNotFoundComponent {

  constructor(private router: RouterNavigateService) {}

  navigateHomepage() {
    this.router.navigate([ROUTER.HOMEPAGE]);
  }
}
