export enum Area {
  ALL = 0,
  KV1 = 1,
  KV2 = 2,
  KV3 = 3,
  KV4 = 4,
  KV5 = 5,
  KV6 = 6,
  KV7 = 7,
  KV8 = 8,
  Kv9 = 9
}