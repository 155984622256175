<div class="bg-white">
  <div class="flex justify-between items-center px-6 py-4 border-b border-b-gray-300">
    <p class="text-black text-base font-bold">Menu</p>
    <button (click)="onClose()">
      <img src="./assets/icons/icon-x-gray.svg" alt="" />
    </button>
  </div>
  <ul nz-menu nzMode="vertical" class="px-6 py-4 border-b border-b-gray-300">
    @for (item of mainMenu; track item.id) {
      <li
        nz-menu-item
        [routerLink]="item.path"
        [queryParams]="queryParams"
        [routerLinkActive]="'active'"
        [nzMatchRouter]="true"
        (click)="onClose()"
        class="text-gray-500 text-base font-medium p-0 main-menu"
      >
        {{item.label}}
      </li>
    }
  </ul>
  <ul nz-menu nzMode="vertical" class="px-6 py-8">
    @for (item of subMenu; track item.id) {
      <li 
        nz-menu-item 
        [routerLink]="item.path"
        [queryParams]="queryParams"
        [routerLinkActive]="'active'"
        [nzMatchRouter]="true"
        (click)="onClose()"
        class="text-dark-cerulean text-base p-0"
      >
        <div class="flex items-center gap-2">
          <img [src]="item.icon" alt="" class="w-6 h-6"/>
          <span>{{item.label}}</span>
        </div>
      </li>
    }
  </ul>
</div>