import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './header/components/navbar/navbar.component';
import { SidebarComponent } from './header/components/sidebar/sidebar.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const components: any[] = [
  MainComponent,
  HeaderComponent,
  FooterComponent,
  NavbarComponent,
  SidebarComponent,
  PageNotFoundComponent
];

@NgModule({
  declarations: components,
  exports: components,
  imports: [
    CommonModule,
    SharedModule,
    RouterModule
  ]
})
export class LayoutsModule { }
