import { Component, OnInit } from '@angular/core';
import { AuthService, DestroyService } from './core/services';
import { LocalStorageUtil } from './core/utils';
import { catchError, takeUntil, throwError } from 'rxjs';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { RouterNavigateService } from './core/services/router-navigate.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private router: RouterNavigateService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private cookieService: CookieService,
    private readonly destroy$: DestroyService
  ) {}

  ngOnInit(): void {
    this.login();
    this.checkQueryParams();
    this.scrollToTop();
  }

  login() {
    if (!LocalStorageUtil.checkItem('accessToken')) {
      this.authService
        .login()
        .pipe(
          catchError((err) => throwError(err)),
          takeUntil(this.destroy$)
        )
        .subscribe((res) => {
          if (res.data && res.data.accessTokenInfo.accessToken) {
            LocalStorageUtil.setItem(
              'accessToken',
              res.data.accessTokenInfo.accessToken
            );
          }
        });
    }
  }

  scrollToTop() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          window.scrollTo({ top: 0 });
        }, 500);
      }
    });
  }

  checkQueryParams() {
    this.activatedRoute.queryParams
      .pipe(
        catchError((err) => throwError(err)),
        takeUntil(this.destroy$)
      )
      .subscribe((params) => {
        if (params['rf']) {
          const expiredDate = new Date();
          expiredDate.setDate(expiredDate.getDate() + 15);
          this.cookieService.set('rf', params['rf'], expiredDate, '/');
        } else {
          this.cookieService.delete('rf');
        }
        if (params['aff_sid']) {
          const expiredDate = new Date();  
          expiredDate.setDate(expiredDate.getDate() + 30);
          this.cookieService.set(
            'aff_sid',
            params['aff_sid'],
            expiredDate,
            '/'
          );
        }
      });
  }
}
