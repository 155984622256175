<nz-radio-group
  [(ngModel)]="value"
  (ngModelChange)="onChange($event)"
  [class]="groupClass"
  [ngClass]="{
    'flex-col': display === 'vertical',
    'flex-row': display === 'horizontal',
    'max-h-[416px] overflow-y-scroll': showScroll
  }"
>
  <ng-container *ngFor="let item of options">
    <label
      *ngIf="item.prefix"
      nz-radio
      [nzValue]="item.value"
      [class]="labelClass"
    >
      <div class="flex items-center gap-2 w-full">
        <img *ngIf="item.prefix" [src]="item.prefix" alt="" />
        <div [innerHTML]="item.label" class="text-[#323232] text-base"></div>
      </div>
    </label>
    
    <label *ngIf="!item.prefix" nz-radio [nzValue]="item.value" [class]="labelClass">
      <div [innerHTML]="item.label" class="text-black-base text-base"></div>
    </label>
  </ng-container>
</nz-radio-group>
