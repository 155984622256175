import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ROUTER } from './shared/constants';
import { MainComponent } from './layouts/main/main.component';
import { PageNotFoundComponent } from './layouts/page-not-found/page-not-found.component';
import { SimNormalComponent } from './modules/sim-normal/sim-normal.component';
import { DesktopGuardService } from './core/services/desktop-guard.service';

const routes: Routes = [
  {
    path: ROUTER.HOMEPAGE,
    component: MainComponent,
    children: [
      {
        path: ROUTER.HOMEPAGE,
        loadChildren: () =>import('./modules/homepage/homepage.module').then((m) => m.HomepageModule),
      },
      {
        path: ROUTER.SIM,
        loadChildren: () => import('./modules/msisdn-detail-and-order-booking/msisdn-detail-and-order-booking.module').then(m => m.MsisdnDetailAndOrderBookingModule)
      },
      {
        path: ROUTER.ORDER_LOOKUP,
        loadChildren: () => import('./modules/order-lookup/order-lookup.module').then((m) => m.OrderLookupModule),
      },
      {
        path: ROUTER.ORDER_RESULT,
        loadChildren: () => import('./modules/order-result/order-result.module').then((m) => m.OrderResultModule),
      },
      {
        path: ROUTER.SHOP_LOCATION,
        loadChildren: () => import('./modules/mobishop-location/mobishop-location.module').then((m) => m.MobishopLocationModule),
      }
    ],
  },
  {
    path: ROUTER.WAP_MOBIFONE,
    loadChildren: () =>import('./modules/wap-my-mobifone/wap-my-mobifone.module').then((m) => m.WapMyMobifoneModule),
    canActivate: [DesktopGuardService]
  },
  {
    path: ROUTER.PAGE_NOT_FOUND,
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
