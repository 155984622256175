<nz-select
  #selectElement
  class="w-full shadow-none"
  [ngClass]="isHasError ? 'ant-select-error' : ''"
  [nzSuffixIcon]="isHasError ? warningIcon : ''"
  [(ngModel)]="value"
  [nzDisabled]="disabled"
  [nzMode]="isMulti ? 'multiple' : 'default'"
  [nzPlaceHolder]="placeholder"
  [nzLoading]="isLoading"
  [nzAllowClear]="allowClear"
  [nzNotFoundContent]="noData"
  (ngModelChange)="onChange($event)"
  [nzSize]="size"
  [nzShowSearch]="showSearch"
>
  <nz-option
    *ngFor="let option of options"
    [nzDisabled]="option.disabled"
    [nzValue]="option.value"
    [nzLabel]="option.label"
  ></nz-option>
</nz-select>

<ng-template #noData>
  <div class="flex flex-col items-center justify-center gap-4">
    <img src="./assets/icons/not-found.svg" alt="" width="60" height="60" />
    <p class="text-black-base text-sm font-bold">Không có dữ liệu</p>
  </div>
</ng-template>

<ng-template #warningIcon>
  <span
    nz-icon
    nzType="info-circle"
    nzTheme="outline"
    class="text-[#F04438] text-base"
  ></span>
</ng-template>