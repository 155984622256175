import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-no-result',
  templateUrl: './modal-no-result.component.html'
})
export class ModalNoResultComponent {

  constructor(
    private modalRef: NzModalRef,
    @Inject(NZ_MODAL_DATA) public data: any
  ) {}

  closeModal() {
    this.modalRef.destroy();
  }
}
