import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[phoneNumber]',
})
export class PhoneNumberDirective {
  constructor(private control: NgControl) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'Enter', 'Tab', 'ArrowRight', 'ArrowLeft', 'Delete'];
    if (allowedKeys.indexOf(event.key) !== -1 ||
      // Allow: Ctrl+A
      (event.key === 'a' && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (event.key === 'c' && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      (event.key === 'v' && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (event.key === 'x' && (event.ctrlKey || event.metaKey))) 
    {
      return;
    }
    if (event.key < '0' || event.key > '9') {
      event.preventDefault();
    }
  }

  @HostListener('input', ['$event'])
  onInput(event: any) {
    this.limitInputDigits(event.target);
  }

  @HostListener('paste', ['$event'])
  onPaste(event: any) {
    const clipboardData = event.clipboardData;
    if (clipboardData) {
      const pastedText = clipboardData.getData('text/plain');
      const onlyDigitsAndAsterisk = pastedText.replace(/[^0-9]/g, '');
      this.limitInputDigits(event.target, onlyDigitsAndAsterisk);
      event.preventDefault();
    }
  }

  private limitInputDigits(input: HTMLInputElement, value: string = '') {
    const inputValue = value || input.value;
    input.value = inputValue.substring(0, 10).replace(/[^0-9]/g, '');
    this.updateModelValue(input.value);
  }

  private updateModelValue(newValue: string) {
    if (this.control) {
      const control = this.control.control;
      control?.setValue(newValue);
    }
  }
}
