<div class="bg-blue-600 rounded-t-lg p-4 flex justify-between">
  <div class="w-6"></div>
  <p class="text-white text-center text-2xl font-semibold">Không thành công</p>
  @if (data.isShowCloseIcon) {
    <button (click)="closeModal()" class="outline-none">
      <img src="./assets/icons/close-modal-x.svg" alt="" />
    </button>
  }
</div>
<div class="bg-white p-4 rounded-b-lg flex flex-col gap-4 items-center">
  <img src="./assets/icons/no-result.svg" alt="" />
  <p class="text-black font-medium text-base">Không tìm thấy dữ liệu. Vui lòng thử lại!</p>
  <button nz-button nzSize="large" class="bg-blue-600 border-none text-white text-base w-1/3 rounded" (click)="closeModal()">Đóng</button>
</div>