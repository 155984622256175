import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export abstract class BaseApiService {
  protected endpoint: string = `${environment.apiUrl}/api/v2`;

  constructor(
    protected http: HttpClient
  ) {}
}
