import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { ZorroAntdModule } from "../zorro-antd.module";
import { NgModule } from "@angular/core";
import { CheckboxComponent } from "./checkbox/checkbox.component";
import { InputComponent } from "./input/input.component";
import { InputGroupComponent } from "./input-group/input-group.component";
import { SelectComponent } from "./select/select.component";
import { DirectivesModule } from "../directives/directives.module";
import { RadioGroupComponent } from "./radio-group/radio-group.component";

const components = [
  CheckboxComponent,
  InputComponent,
  InputGroupComponent,
  SelectComponent,
  RadioGroupComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ZorroAntdModule,
    DirectivesModule
  ],
  declarations: components,
  exports: components
})
export class ControlsModule {}
