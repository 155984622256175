import {
  DATE_SQL_FORMAT,
  DATE_TIME_FULL_FORMAT,
  DATE_TIME_SQL_FORMAT,
  DATE_TIME_FORMAT,
  DATE_TIME_TEXT_FORMAT,
  DATE_FORMAT,
  YEAR_FORMAT,
  DATE_TIME_TEXT_FORMAT_FULL,
  MONTH_FORMAT,
  DATE_FORMAT3
} from '../constants';
import { format } from 'date-fns';

export class DateUtil {
  static today() {
    return DateUtil.formatSQL(new Date());
  }

  static now() {
    return DateUtil.formatTimeSQL(new Date());
  }

  static formatDate(date: Date, formatStr = DATE_FORMAT){
    return format(date, formatStr);
  }

  static formatSQL(date: Date, formatStr = DATE_SQL_FORMAT) {
    return format(date, formatStr);
  }

  static formatTimeSQL(date: Date, formatStr = DATE_TIME_SQL_FORMAT) {
    return format(date, formatStr);
  }

  static formatTimeToView(date: Date, formatStr = DATE_TIME_FULL_FORMAT) {
    return format(date, formatStr);
  }

  static formatDateTime(date: Date, formatStr = DATE_TIME_FORMAT) {
    return format(date, formatStr);
  }

  static formatDateTimeToText(date: Date, formatStr = DATE_TIME_TEXT_FORMAT) {
    return format(date, formatStr);
  }
  static formatDateTimeToTextFull(date: Date, formatStr = DATE_TIME_TEXT_FORMAT_FULL) {
    return format(date, formatStr);
  }

  static isFutureDate(date: Date) {
    return new Date(date).getTime() >= new Date().getTime();
  }

  static formatYear(date: Date) {
    return format(date, YEAR_FORMAT);
  }
  static formatMonth(date: Date) {
    return format(date, MONTH_FORMAT);
  }

  static DateStringToMilisecond(endOfTime: string) {
    const [date, time] = endOfTime.split(' ');
    const [day, month, year] = date.split('/');
    const [hour, minute, second] = time.split(':');
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day), parseInt(hour), parseInt(minute), parseInt(second)).getTime();
  }
}
