import { ITableColumns } from '../models/table-columns.interface';

export const TABLE_COLUMNS_DESKTOP: ITableColumns[] = [
  { key: 'msisdn', title: 'Số thuê bao', isBold: true, isMsisdn: true },
  { key: 'typeStr', title: 'Loại thuê bao' },
  { key: 'dwTypeName', title: 'Khu vực hòa mạng', isShowListLocation: true },
  {
    key: 'roamingFee',
    title: 'Phí hòa mạng',
    isBold: true,
    isShowCurrency: true,
  },
  { key: '', title: '' },
];

export const TABLE_COLUMNS_MOBILE: ITableColumns[] = [
  { key: 'msisdn', title: 'Số thuê bao', isBold: true,  isMsisdn: true },
  { key: 'typeStr', title: 'Loại thuê bao' },
  {
    key: 'roamingFee',
    title: 'Phí hòa mạng',
    isBold: true,
    isShowCurrency: true,
  },
];
