<header class="container mx-auto py-4">
  <div
    [ngClass]="
      isShowStep
        ? 'flex max-lg:flex-col gap-4 justify-between items-center'
        : 'flex max-xl:justify-between items-center'
    "
  >
    <div class="lg:w-1/4">
      <a
        href="https://www.mobifone.vn/"
        [ngClass]="isShowStep ? 'max-lg:flex justify-center' : ''"
      >
        <img src="./assets/images/logo-mobifone.webp" alt="logo MobiFone" />
      </a>
    </div>
    @if (isShowStep) {
    <div>
      <app-step [index]="stepIndex"></app-step>
    </div>
    } @else {
    <div class="hidden xl:block lg:w-3/4">
      <app-navbar></app-navbar>
    </div>
    <div class="block xl:hidden">
      <button
        class="bg-gray-200 rounded-lg p-2 focus-visible:outline-none"
        (click)="isSideMenuVisible = !isSideMenuVisible"
        aria-label="drawer-menu"
      >
        <img src="./assets/icons/menu-bar.svg" alt="" />
      </button>
      <nz-drawer
        [nzVisible]="isSideMenuVisible"
        [nzClosable]="false"
        [nzPlacement]="'right'"
        [nzMaskStyle]="{ top: '68px' }"
        [nzWidth]="322"
        [nzBodyStyle]="{ padding: 0 }"
        nzPlacement="left"
      >
        <ng-container *nzDrawerContent>
          <app-sidebar (close)="isSideMenuVisible = false"></app-sidebar>
        </ng-container>
      </nz-drawer>
    </div>
    }
  </div>
</header>
